import axios from 'axios';

export const getKey = async () => {
  return axios.get('/payments/cybersource-key').then(
    (res) => ({ ok: true, key: res.data }),
    (error) => {
      console.error('status: ', error?.response?.status);
      console.error('error response: ', JSON.stringify(error?.response?.data));
      return {
        ok: false,
        errorMessage: 'paymentMethods.createPaymentMethod.errorMessage',
      };
    },
  );
};
