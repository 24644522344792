// TODO  Mover este archivo a una conf

export const arInsurers = [
  {
    title: 'Allianz',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/allianz',
  },
  {
    title: 'Galeno',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/galeno',
  },
  {
    title: 'Mapfre',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/mapfre',
  },
  {
    title: 'Mercantil Andina',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/mercantil-andina',
  },
  {
    title: 'Meridional',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/meridional',
  },
  {
    title: 'Orbis',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/orbis',
  },
  {
    title: 'Provincia',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/provincia',
  },
  {
    title: 'Prudencia',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/prudencia',
  },
  {
    title: 'Sancor',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/sancor',
  },
  {
    title: 'Sura',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/sura',
  },
  {
    title: 'Zurich',
    externalPath: 'https://www.123seguro.com.ar/aseguradoras/zurich',
  },
];

export const clInsurers = [
  {
    title: 'BCI',
    externalPath: 'https://www.123seguro.cl/aseguradoras/bci',
  },
  {
    title: 'Mapfre',
    externalPath: 'https://www.123seguro.cl/aseguradoras/mapfre',
  },
  {
    title: 'Reale',
    externalPath: 'https://www.123seguro.cl/aseguradoras/reale',
  },
  {
    title: 'Sura',
    externalPath: 'https://www.123seguro.cl/aseguradoras/sura',
  },
  {
    title: 'Zenit',
    externalPath: 'https://www.123seguro.cl/aseguradoras/zenit',
  },
  {
    title: 'Chilena Consolidada',
    externalPath: 'https://www.123seguro.cl/aseguradoras/chilena-consolidada',
  },
];

export const coInsurers = [
  {
    title: 'Allianz',
    externalPath: `https://www.123seguro.com.co/aseguradoras/allianz`,
  },
  {
    title: 'AXA',
    externalPath: `https://www.123seguro.com.co/aseguradoras/axa`,
  },
  {
    title: 'Equidad',
    externalPath: `https://www.123seguro.com.co/aseguradoras/equidad`,
  },
  {
    title: 'HDI',
    externalPath: `https://www.123seguro.com.co/aseguradoras/hdi`,
  },
  {
    title: 'Liberty',
    externalPath: `https://www.123seguro.com.co/aseguradoras/liberty`,
  },
  {
    title: 'Mapfre',
    externalPath: `https://www.123seguro.com.co/aseguradoras/mapfre`,
  },
  {
    title: 'SBS',
    externalPath: `https://www.123seguro.com.co/aseguradoras/sbs`,
  },
  {
    title: 'Bolívar',
    externalPath: `https://www.123seguro.com.co/aseguradoras/bolivar`,
  },
  {
    title: 'Sura',
    externalPath: `https://www.123seguro.com.co/aseguradoras/sura`,
  },
  {
    title: 'Solidaria',
    externalPath: `https://www.123seguro.com.co/aseguradoras/solidaria`,
  },
];
