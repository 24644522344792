/* eslint-disable react/jsx-wrap-multilines */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Alert,
  Button,
  Icon,
  icons,
  colors,
  respondTo,
  bodyFont,
  titleFont,
  getWhatsappLink,
} from '@123-front/ui-kit';
import RequestEmailForm from './RequestEmailForm';
import ForgotUser from './ForgotUser';
import { authContainer } from './styles';
import { textType } from './propTypes';

const styles = {
  ...authContainer,
  '.arrowBackIcon': {
    marginLeft: 0,
    marginTop: 0,
  },
  '.header': {
    position: 'relative',
  },
  '.tips': {
    ...bodyFont('sm'),
    color: colors.gray.dark,
    '> p': { ...bodyFont('sm') },
    ul: {
      padding: 0,
      margin: 0,
      listStylePosition: 'inside',
    },
    a: { color: 'inherit', textDecoration: 'underline' },
  },
  '.back': {
    background: 'none',
    border: 0,
    position: 'absolute',
    left: 0,
  },
  '.title': {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    margin: '24px 0 0 0',
    textAlign: 'center',
  },
  '.help-needed': {
    display: 'contents',
    textDecoration: 'underline',
    fontWeight: 'normal',
  },
  '.top-text': {
    ...bodyFont('md'),
    marginTop: 12,
    marginBottom: 20,
    textAlign: 'center',
  },
  '.forgot-mail': {
    marginBottom: 16,
  },
  '.feedback-alert': {
    marginBottom: 16,
    '.access': {
      display: 'inline',
      textDecoration: 'underline',
      cursor: 'pointer',
      outline: 'none',
    },
  },
  '.sucess-alert': {
    marginTop: 24,
    marginBottom: 24,
  },
  '.resend': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  [respondTo('md')]: {
    '.arrowBackIcon': {
      marginLeft: -24,
      marginTop: 8,
    },
  },
};

const renderMailSent = (config, texts, mail, onBackClick) => {
  return (
    <section>
      <div className="header">
        <Button className="back" type="button" variant="link" onClick={onBackClick}>
          <Icon icon={icons.back} size="24" color={colors.cyan.dark} className="arrowBackIcon" />
        </Button>
        <h3 className="title">{texts.form.title}</h3>
      </div>
      <Alert
        type="success"
        className="sucess-alert"
        text={texts.alert.sent
          .replaceAll('[[mail]]', mail)
          .replaceAll('[[successText]]', texts.success)}
      />
      <div className="tips">
        <p>{texts.tips.title}</p>
        <div>{texts.tips.tip1}</div>
        <div>{texts.tips.tip2}</div>
        <div>
          {texts.tips.tip3.first}
          <span
            className="resend"
            onClick={onBackClick}
            onKeyDown={onBackClick}
            role="button"
            tabIndex="0"
          >
            {texts.tips.tip3.second}
          </span>
        </div>
        <div>
          {texts.tips.tip4.first}
          <a href={getWhatsappLink(config.whatsapp)} target="_blank" rel="noopener noreferrer">
            {texts.tips.tip4.second}
          </a>
          {texts.tips.tip4.third}
          <a href={`mailto:${config.customerSupport}`}>{config.customerSupport}</a>
        </div>
      </div>
    </section>
  );
};

const RequestEmail = ({
  config,
  initialEmail,
  texts,
  sendEmail,
  onBackClick,
  onSuccess,
  onResend,
  onUnhandledError,
}) => {
  const [mail, setMail] = useState(initialEmail);
  const [submited, setSubmited] = useState(false);
  const [forgotUser, setForgotUser] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const alertContent = (
    <div>
      {texts.alert.title}
      <Button
        block
        variant="link"
        color="danger"
        className="help-needed"
        onClick={() => setForgotUser(true)}
      >
        {texts.alert.button}
      </Button>
    </div>
  );

  const handleOnSubmit = async (value) => {
    setMail(value);
    setShowError(false);
    try {
      await sendEmail(value);
    } catch (error) {
      if (!error.response) {
        setApiErrorMsg(texts.apiErrorMsg.replaceAll('[[whatsapp]]', config.whatsapp));
        setShowError(true);
      } else if (error.response.data[0].status === 404) {
        setApiErrorMsg(alertContent);
        setShowError(true);
      } else if (error.response.data[0].status === 403) {
        setShowHelp(true);
      } else {
        onUnhandledError(error.response.data[0]);
      }
      return;
    }
    onSuccess();
    setSubmited(true);
  };

  return (
    <section css={styles}>
      {!submited && !forgotUser ? (
        <div>
          <div className="header">
            {onBackClick && (
              <Button className="back" type="button" variant="link" onClick={onBackClick}>
                <Icon
                  icon={icons.back}
                  size="24"
                  color={colors.cyan.dark}
                  className="arrowBackIcon"
                />
              </Button>
            )}
            <h3 className="title">{texts.form.title}</h3>
          </div>
          <div className="top-text">{texts.form.topText}</div>
          <RequestEmailForm
            value={mail}
            onSubmit={handleOnSubmit}
            buttonText={texts.submitButton}
            texts={{ form: { email: texts.form.email } }}
          >
            <div>
              {showError && (
                <Alert dismissible text={apiErrorMsg} className="feedback-alert" type="error" />
              )}
              {showHelp && (
                <Alert
                  dismissible
                  text={
                    <div>
                      {texts.alert.registered.title}
                      <div
                        onClick={onBackClick}
                        onKeyDown={onBackClick}
                        role="button"
                        tabIndex="0"
                        className="access"
                      >
                        {texts.alert.registered.button}
                      </div>
                    </div>
                  }
                  className="feedback-alert"
                  type="info"
                />
              )}
              <Button
                block
                variant="link"
                color="interactive"
                className="forgot-mail"
                onClick={() => {
                  setForgotUser(true);
                }}
              >
                {texts.forgotEmail}
              </Button>
            </div>
          </RequestEmailForm>
        </div>
      ) : undefined}
      {forgotUser ? (
        <ForgotUser config={config} texts={texts.forgotUser} setForgotUser={setForgotUser} />
      ) : undefined}
      {submited
        ? renderMailSent(
            config,
            texts,
            mail,
            () => {
              setSubmited(false);
              onResend();
            },
            texts.success,
          )
        : undefined}
    </section>
  );
};

RequestEmail.propTypes = {
  initialEmail: PropTypes.string,
  config: PropTypes.shape({
    whatsapp: PropTypes.string.isRequired,
    customerSupport: PropTypes.string.isRequired,
  }).isRequired,
  texts: PropTypes.shape({
    tips: PropTypes.object,
    form: PropTypes.object,
    forgotUser: textType,
    forgotEmail: PropTypes.string,
    submitButton: PropTypes.string,
    success: PropTypes.string,
    alert: PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.string,
      registered: PropTypes.shape({
        title: PropTypes.string,
        button: PropTypes.string,
      }),
      sent: PropTypes.string,
    }),
    apiErrorMsg: PropTypes.string,
  }),
  onBackClick: PropTypes.func,
  onUnhandledError: PropTypes.func,
  onSuccess: PropTypes.func,
  onResend: PropTypes.func,
  sendEmail: PropTypes.func.isRequired,
};

export default RequestEmail;
