import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

const styles = {
  display: 'flex',
  alignItems: 'center',
  img: {
    marginRight: 16,
  },
};

export const CountryOption = ({ country, className = '' }) => {
  return (
    <div css={styles} className={className}>
      <img alt={country.name} src={country.flag} />
      <p>{country.name}</p>
    </div>
  );
};

CountryOption.propTypes = {
  country: PropTypes.shape({
    name: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};
