/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { AuthService } from './AuthService';
import { NewPassword } from './NewPassword';
import { authContainer } from './styles';

export const ChangePassword = ({ texts, userId, token, onSubmit }) => {
  const { t } = useSelfServiceTranslation();
  const changePassword = (password) => AuthService.newPassword(userId, password, token);

  return (
    <section css={{ ...authContainer }}>
      <NewPassword
        texts={texts}
        onSubmit={onSubmit}
        changePassword={changePassword}
        config={{ whatsapp: t('attention.whatsapp') }}
      />
    </section>
  );
};

ChangePassword.propTypes = {
  texts: PropTypes.shape({
    form: PropTypes.shape({
      title: PropTypes.string,
      topText: PropTypes.string,
      button: PropTypes.string,
      password: PropTypes.shape({
        errorMandatory: PropTypes.string,
        errorFormat: PropTypes.string,
        label: PropTypes.string,
        info: PropTypes.string,
      }),
      errors: PropTypes.shape({
        unexpected: PropTypes.string,
      }),
    }),
  }),
  userId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
