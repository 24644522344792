import { getEventObject } from './service';

import { ANALYTICS_PUSH_EVENT } from './actionTypes';

/**
 * @param  {string} event Ej: trackError
 * @param  {string } label Ej: not found 404
 * @param  {string} action Ej: error-campo
 * @param  {object} additionalData
 * @param  {string} category Ej: autogestion
 */
export const analyticsPushEvent =
  (event, label, action, additionalData, category) => async (dispatch) => {
    dispatch({ type: ANALYTICS_PUSH_EVENT });
    const eventObj = getEventObject(event, label, action, additionalData, category);
    window.dataLayer.push(eventObj);
  };
