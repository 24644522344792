import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Column, respondTo, colors } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import config from '../config';
import history from '../history';
import { SignIn } from '../shared/auth';
import BackgroundShapes from '../shared/components/BackgroundShapes';
import { PublicHeader } from '../shared/header/PublicHeader';
import PublicFooter from '../shared/footer/PublicFooter';
import { isAvailableCountry } from '../isAvailableCountry';
import { domainLocation } from '../shared/domainResolver';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { androidAppLink, iosAppLink } from '../shared/links';
import { signIn } from './store/actions';

/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  padding: '0 8px',
  minHeight: 'calc(100vh - 488px)',
  '.row-signin': {
    background: colors.blue.dark,
    '& > div': {
      background: 'rgba(61,90,254,0.3)',
      padding: '0 0 40px 0',
    },
  },
  [respondTo('lg')]: {
    padding: '40px 0 60px 0',
    '.row-signin': {
      background: 'transparent',
      justifyContent: 'space-around',
      '& > div': {
        background: 'transparent',
        paddingBottom: 0,
        '&> section': {
          borderRadius: 4,
        },
      },
    },
  },
};

// eslint-disable-next-line no-shadow
const Login = ({ signIn, menuClose }) => {
  const { t, basePath } = useSelfServiceTranslation();
  const texts = {
    form: {
      title: t('login.form.title'),
      email: {
        label: t('login.form.email.label'),
        info: t('login.form.email.info'),
        errorMandatory: t('login.form.email.errorMandatory'),
        errorFormat: t('login.form.email.errorFormat'),
      },
      password: {
        label: t('login.form.password.label'),
        info: t('login.form.password.info'),
        errorMandatory: t('login.form.password.errorMandatory'),
        errorFormat: t('login.form.password.errorFormat'),
      },
      button: t('login.form.button'),
      forgotPassword: t('login.form.forgotPassword'),
      errors: {
        wrongCredentials: t('login.form.errors.wrongCredentials'),
        needHelp: t('login.form.errors.needHelp'),
        unexpected: t('login.form.errors.unexpected'),
        notActive: t('login.form.errors.notActive'),
        activeAccountHelp: t('login.form.errors.activeAccountHelp'),
      },
    },
    registerTitle: t('login.registerTitle'),
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onForgotPassword = () => history.push(t('routes.auth.forgotPassword'));
  const onRegistermeClick = (email) => history.push(t('routes.auth.registerSendEmail'), { email });
  const onSubmit = ({ token, mail }) => {
    signIn(token, mail, basePath);
  };
  //TODO: si los links para las appstore varian segun pais, refactorizar como corresponda
  return (
    <div>
      <BackgroundShapes />
      <PublicHeader menuClose={menuClose} />
      <div css={styles}>
        <Container>
          <Row className="row-signin">
            <Column mobile={12} desktop={5}>
              <SignIn
                texts={texts}
                config={{ whatsapp: t('attention.whatsapp') }}
                onSubmit={onSubmit}
                applicationId={config.applicationId}
                onForgotPasswordClick={onForgotPassword}
                onActivateAccountClick={onRegistermeClick}
                onRegistermeClick={onRegistermeClick}
                showForm={isAvailableCountry(domainLocation)}
                androidStoreLink={androidAppLink}
                iosStoreLink={iosAppLink}
              />
            </Column>
          </Row>
        </Container>
      </div>
      <PublicFooter />
    </div>
  );
};

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
  menuClose: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  menuClose: state.shared.menuClose,
});

export default connect(mapStateToProps, { signIn })(Login);
