import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Input,
  OptionSelector,
  colors,
  makeMeColumn,
  titleFont,
  scrollToTop,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { container } from '../../assets/styles';
import { userPropTypes } from '../../auth/store/reducer-proptypes';
import { carPropTypes } from '../../cars/store/reducer-proptypes';
import history from '../../history';
import { findCarByPlate } from '../../cars/car.utils';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { ContactService } from './contactService';

/** @jsxRuntime classic */
/** @jsx jsx */

const margin = {
  marginBottom: 16,
};

const styles = {
  '.form-content': {
    ...container,
    ...makeMeColumn(1, 1, 16),
    paddingBottom: 40,
  },
  '.title': {
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
    fontWeight: 'bold',
    marginBottom: 32,
    marginTop: 32,
    textAlign: 'center',
  },
  '.optionSelector': {
    ...margin,
  },
  '.input': {
    ...margin,
  },
  '.api-error': {
    marginBottom: 16,
  },
};

const InnerContact = ({ car, profile, plate }) => {
  const { t } = useSelfServiceTranslation();

  const [submitActive, setSubmitActive] = useState(true);
  const [values, setValues] = useState({
    reason: '',
    description: '',
  });
  const [errorMsgs, setErrorMsgs] = useState({ reason: '', description: '' });
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  const reasons = useMemo(
    () => [
      { description: t('consultations.options.myCoverage') },
      { description: t('consultations.options.documentation') },
      { description: t('consultations.options.payments') },
      { description: t('consultations.options.claims') },
      { description: t('consultations.options.modifications') },
      { description: t('consultations.options.renewal') },
      { description: t('consultations.options.others') },
    ],
    [t],
  );

  const handleReason = (reason) => {
    setErrorMsgs({ ...errorMsgs, reason: '' });
    setValues({ ...values, reason });
  };

  const handleDescription = (event) => {
    setErrorMsgs({ ...errorMsgs, description: '' });
    setValues({ ...values, description: event.target.value });
  };

  const getDescriptionError = () => {
    return !values.description ? t('contact.form.description.emptyError') : null;
  };

  const onDescriptionBlur = () => {
    const descriptionError = getDescriptionError();
    if (descriptionError) {
      setErrorMsgs({ ...errorMsgs, description: descriptionError });
    }
  };

  const getReasonError = () => {
    return !values.reason ? t('contact.form.reason.emptyError') : null;
  };

  const onOptionSelectorClick = (optionsDisplayed) => {
    if (!optionsDisplayed) {
      const reasonError = getReasonError();
      if (reasonError) {
        setErrorMsgs({ ...errorMsgs, reason: reasonError });
      }
    } else {
      setErrorMsgs({ ...errorMsgs, reason: '' });
    }
  };

  const sendQuery = async (event) => {
    event.preventDefault();
    const reasonError = getReasonError();
    const descriptionError = getDescriptionError();
    setErrorMsgs({ reason: reasonError, description: descriptionError });
    if (!reasonError && !descriptionError) {
      setSubmitActive(false);
      try {
        const data = {
          ...values,
          reason: values.reason.description,
          policyNumber: car.policyNumber,
          patent: car.plate,
          dni: profile.legalId.number,
        };
        await ContactService.sendClaim(data);
        scrollToTop();
      } catch (e) {
        setSubmitActive(true);
        setApiErrorMsg(t('generals.genericError', { whatsapp: t('attention.whatsapp') }));
        return;
      }
      history.push(t('routes.dashboard.contactSuccess', { plate }));
    }
  };

  return (
    <div css={styles}>
      <SelfmanagmentNavbar plate={plate} activeTab={NavtabId.CONTACT} />
      <h1 className="title">{t('contact.title')}</h1>
      <div className="form-content">
        <form onSubmit={sendQuery}>
          <OptionSelector
            label={t('contact.form.reason.label')}
            render="description"
            selectedItem={values.reason}
            error={errorMsgs.reason}
            items={reasons}
            onChange={handleReason}
            onClick={onOptionSelectorClick}
          />
          <Input
            className="input"
            tag="textarea"
            rows={5}
            maxLength={500}
            disabled={false}
            error={errorMsgs.description}
            label={t('contact.form.description.label')}
            onBlur={onDescriptionBlur}
            onChange={handleDescription}
            value={values.description}
          />
          {apiErrorMsg && (
            <Alert dismissible text={apiErrorMsg} className="api-error" type="error" />
          )}
          <Button
            block
            color="interactive"
            disabled={!submitActive}
            size="md"
            type="submit"
            variant="primary"
          >
            {t('contact.form.button')}
          </Button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    plate: ownProps.match.params.plate,
    car: findCarByPlate(state.cars.cars, ownProps.match.params.plate),
    profile: state.auth.user,
  };
};

InnerContact.propTypes = {
  plate: PropTypes.string.isRequired,
  profile: userPropTypes.isRequired,
  car: carPropTypes,
};

export const Contact = connect(mapStateToProps)(InnerContact);
