/** @jsxRuntime classic */
/** @jsx jsx */

import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { Button, bodyFont, colors } from '@123-front/ui-kit';

const styles = {
  '.title': {
    position: 'relative',
    zIndex: 2,
    ...bodyFont('lg'),
    color: '#fff',
    textAlign: 'center',
    marginBottom: 16,
  },
  '.button-container': {
    position: 'relative',
    zIndex: 2,
    backgroundColor: '#fff',
    width: '100%',
    border: ` 1px solid ${colors.gray.lighten}`,
    boxSizing: 'border-box',
    borderRadius: 4,
    textAlign: 'center',
    '.store-button': {
      height: 'inherit',
      paddingLeft: 'inherit',
      paddingRight: 'inherit',
      backgroundColor: 'inherit',
    },
    '.android-button': {
      width: '100%',
      marginTop: 40,
      height: 40,
    },
    '.ios-button': {
      width: '100%',
      marginTop: 25,
      marginBottom: 40,
      height: 40,
    },
  },
};

const SignInStoreButtons = ({ iosStoreLink, androidStoreLink, texts }) => {
  const handleStoreRedirect = (storeUrl) => {
    window.open(storeUrl);
  };
  return (
    <section css={styles}>
      <div>
        <div className="title">{texts.title}</div>
        <div className="button-container">
          <div className="android-button">
            <Button className="store-button" onClick={() => handleStoreRedirect(androidStoreLink)}>
              <img
                alt="android"
                src="https://branding.s3.us-west-2.amazonaws.com/logotypes/badges/badge_store_android.svg"
              />
            </Button>
          </div>
          <div className="ios-button">
            <Button className="store-button" onClick={() => handleStoreRedirect(iosStoreLink)}>
              <img
                alt="ios"
                src="https://branding.s3.us-west-2.amazonaws.com/logotypes/badges/badge_store_ios.svg"
              />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

// TODO mejorar estas configs con i18n
SignInStoreButtons.propTypes = {
  iosStoreLink: PropTypes.string,
  androidStoreLink: PropTypes.string,
  texts: PropTypes.shape({
    title: PropTypes.string,
  }),
};

SignInStoreButtons.defaultProps = {
  texts: {
    title: 'Descarga la app, ingresa con tus datos, y gestiona tu seguro!',
  },
};

export default SignInStoreButtons;
