import * as actions from './actionTypes';

const INITIAL_STATE = {
  oneTrustId: null,
  didContextLoad: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.LOAD_CONTEXT:
      return INITIAL_STATE;

    case actions.LOAD_CONTEXT_SUCCESS:
      return {
        ...state,
        oneTrustId: action.payload.oneTrustId,
        didContextLoad: true,
      };
    case actions.LOAD_CONTEXT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};
