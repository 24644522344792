/**
 * getEventObject
 * @description Gets Events object from store
 * @param  {string} event  Name
 * @param  {string} eLab Event's label
 * @param  {string} [eAct='download-app'] Event's action
 * @param  {string} [additionalData=null] Already stored Data
 * @param  {string} [eCat='autogestion'] Event's
 * @returns {object} { event, eCat, eAct, eLab, additionalData }
 */
export const getEventObject = (event, eLab, eAct, additionalData = null, eCat = 'autogestion') => {
  const ad = additionalData ? { additionalData } : additionalData;
  return { event, eCat, eAct, eLab, ...ad };
};
