/** @jsxRuntime classic */
/** @jsx jsx */
import { bodyFont, subtitleFont } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const titleStyle = {
  ...subtitleFont('xs'),
  textTransform: 'uppercase',
  marginBottom: 4,
};

const valueStyle = {
  ...bodyFont('sm'),
  marginBottom: 0,
};

export const HolderDetail = ({ title, value, color }) => {
  return (
    <div>
      <p css={{ ...titleStyle, color }}>{title}</p>
      <p css={{ ...valueStyle, color }}>{value ? value : '\u00a0\u00a0'}</p>
    </div>
  );
};

HolderDetail.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
};
