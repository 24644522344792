import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { bodyFont, colorTheme, Modal } from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { AppDownloadButtons } from '../../../shared/components/AppDownloadButtons';

const styles = {
  '.paragraph': {
    marginBottom: 32,
    ...bodyFont('lg'),
    color: colorTheme.textDefault,
  },
  '.divider': {
    marginTop: 32,
    marginBottom: 32,
  },
  '.app-download-buttons': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
};

export const SelectAppModal = ({ className = '', manualLink, ...props }) => {
  const { t } = useSelfServiceTranslation();
  return (
    <Modal {...props} title={t('downloadAppModal.title')} className={className}>
      <div css={styles} className={className}>
        <p className="paragraph">{t('downloadAppModal.subtitle')}</p>
        <div className="divider">
          <p className="paragraph">{t('downloadAppModal.useApp')}</p>
          <AppDownloadButtons className="app-download-buttons" />
        </div>
        <div>
          <p className="paragraph">
            {t('downloadAppModal.useManual')}
            <a className="link" href={manualLink} target="_blank" rel="noopener noreferrer">
              {t('downloadAppModal.useManualLink')}
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
};

SelectAppModal.propTypes = {
  className: PropTypes.string,
  manualLink: PropTypes.string.isRequired,
};
