import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { makeMeContainer, respondTo, colors, titleFont } from '@123-front/ui-kit';
import { lazy, Suspense } from 'react';
import { findCarByPlate } from '../../cars/car.utils';
import { container } from '../../assets/styles';
import { companyPropTypes } from '../../cars/store/reducer-proptypes';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { domainLocation } from '../../shared/domainResolver';
/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  ...container,
  [respondTo('lg')]: {
    ...makeMeContainer(600),
  },
  '.title': {
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
    margin: '24px 0 24px 0',
    textAlign: 'center',
    textTransform: 'capitalize',
  },

  '.phone-column': {
    marginBottom: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    p: {
      fontSize: 13,
      marginTop: 8,
      marginBottom: 4,
    },
    '.phone': {
      '+ .phone': {
        marginTop: 4,
      },
    },
  },
};

const loadCallForHelpContent = (company, location) => {
  const CallForHelpContent = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/CallForHelpContent`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <CallForHelpContent company={company} />;
};

export const InnerCallForHelpPage = ({ company, plate }) => {
  const { t } = useSelfServiceTranslation();

  return (
    <div>
      <SelfmanagmentNavbar plate={plate} activeTab={NavtabId.HELP} />
      <div css={styles}>
        {company && (
          <div className="container">
            <p className="title">
              {t('callForHelp.help')} {company.name}
            </p>
            <Suspense fallback={<div />}>
              {loadCallForHelpContent(company, domainLocation)}
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
};

InnerCallForHelpPage.propTypes = {
  company: companyPropTypes,
  plate: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const car = findCarByPlate(state.cars.cars, ownProps.match.params.plate);
  return {
    plate: ownProps.match.params.plate,
    company: car ? car.company : null,
  };
};

export const CallForHelpPage = connect(mapStateToProps)(InnerCallForHelpPage);
