import React from 'react';
import PropTypes from 'prop-types';
import RequestEmail from './RequestEmail';
import { AuthService } from './AuthService';
import { textType } from './propTypes';

export const RequestRegistrationEmail = ({
  initialEmail,
  urlToRedirect,
  config,
  texts,
  onBackClick,
  onUnhandledError,
  onSuccess,
  onResend,
}) => (
  <RequestEmail
    initialEmail={initialEmail}
    config={config}
    sendEmail={(value) => AuthService.sendRegisterMail(value, urlToRedirect)}
    texts={texts}
    onUnhandledError={onUnhandledError}
    onBackClick={onBackClick}
    onResend={onResend}
    onSuccess={onSuccess}
  />
);

RequestRegistrationEmail.propTypes = {
  initialEmail: PropTypes.string,
  urlToRedirect: PropTypes.string,
  config: PropTypes.shape({
    whatsapp: PropTypes.string.isRequired,
    customerSupport: PropTypes.string.isRequired,
  }).isRequired,
  texts: PropTypes.shape({
    tips: PropTypes.object,
    form: PropTypes.object,
    forgotUser: textType,
    forgotEmail: PropTypes.string,
    submitButton: PropTypes.string,
    success: PropTypes.string,
    alert: PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.string,
      registered: PropTypes.shape({
        title: PropTypes.string,
        button: PropTypes.string,
      }),
      sent: PropTypes.string,
    }),
    apiErrorMsg: PropTypes.string,
  }),
  onSuccess: PropTypes.func,
  onResend: PropTypes.func,
  onBackClick: PropTypes.func.isRequired,
  onUnhandledError: PropTypes.func.isRequired,
};
