import { useState } from 'react';
import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  bodyFont,
  Icon,
  icons,
  getWhatsappLink,
  colorTheme,
  ButtonWrapper,
} from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { countryDomain } from '../../../shared/domainResolver';
import { CountrySelectorModal } from './CountrySelectorModal';

const styles = {
  padding: 16,
  paddingBottom: 30,
  '.reason-title': {
    marginBottom: 16,
  },

  '.reason-bullets': {
    marginBottom: 24,
    '.reason-bullet': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
      svg: {
        flexShrink: 0,
        marginRight: 10,
        path: {
          fill: '#00E676',
        },
      },
    },

    '.change-country-text': {
      p: {
        display: 'inline-block',
        marginRight: 4,
      },
      '.change-country-link': {
        marginRight: 0,
        cursor: 'pointer',
        color: colorTheme.interactive,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },

  '.reason-help': {
    marginBottom: 16,
  },

  '.reason-contact': {
    svg: {
      marginRight: 8,
    },
  },

  p: {
    ...bodyFont('md', 'sans'),
    marginBottom: 0,
  },
};

export const UserNotFoundReasons = ({ className = '' }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useSelfServiceTranslation();

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div css={styles} className={className}>
      <p className="reason-title">{t('userNotFound.reason.text.header')}</p>

      <div className="reason-bullets">
        <div className="reason-bullet">
          <Icon icon={icons.ok} />
          <div className="change-country-text">
            <p>{t('userNotFound.reason.text.bullets.beFrom')}</p>
            <ButtonWrapper onClick={openModal}>
              <p className="change-country-link">
                {t('userNotFound.reason.text.bullets.changeCountry')}
              </p>
            </ButtonWrapper>
          </div>
        </div>
        <div className="reason-bullet">
          <Icon icon={icons.ok} />
          <p>{t('userNotFound.reason.text.bullets.beClient')}</p>
        </div>
        <div className="reason-bullet">
          <Icon icon={icons.ok} />
          <p>{t('userNotFound.reason.text.bullets.havePolicy')}</p>
        </div>
        <div className="reason-bullet">
          <Icon icon={icons.ok} />
          <p>{t('userNotFound.reason.text.bullets.correctMail')}</p>
        </div>
      </div>

      <p className="reason-help">{t('userNotFound.reason.text.help')}</p>

      <div className="reason-contact">
        <Icon icon={icons.whatsapp} size="md" />
        <a
          href={getWhatsappLink(
            t('attention.whatsapp'),
            t('userNotFound.reason.whatsappMessage', {
              domain: countryDomain,
              mail: localStorage.getItem('mail'),
            }),
          )}
        >
          {t('attention.whatsapp')}
        </a>
      </div>

      <CountrySelectorModal show={showModal} onClose={closeModal} />
    </div>
  );
};

UserNotFoundReasons.propTypes = {
  className: PropTypes.string,
};
