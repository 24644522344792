/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Button,
  Container,
  bodyFont,
  colorTheme,
  subtitleFont,
  titleFont,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { useParams } from 'react-router-dom';
import { FixedFooter } from '../paymentMethods/FixedFooter';
import { Header } from './Header';

const styles = {
  height: '100vh',
  h1: titleFont('serif', 'lg'),
  h2: { ...subtitleFont('md'), color: colorTheme.textMute },
  p: bodyFont('md'),
};

const TermsConditions = () => {
  const { channel } = useParams();

  return (
    <main css={styles}>
      <Header channel={channel} />
      <Container>
        <h2>LEGALES</h2>
        <h1>Términos y condiciones</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque sunt quae dolorem culpa.
          Adipisci reiciendis recusandae atque blanditiis dolorum, natus voluptatibus nulla eligendi
          laborum quisquam voluptatum libero suscipit earum sunt.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum, vel tenetur. Neque
          aliquid exercitationem, vero, similique excepturi culpa perspiciatis quia odit sequi ipsam
          asperiores, dolorem adipisci architecto sapiente mollitia ab.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione ut repellendus nulla
          temporibus obcaecati! Aut, fuga excepturi vitae numquam adipisci enim, sapiente, libero
          dignissimos architecto perspiciatis fugiat deserunt amet. Tempore?
        </p>
      </Container>
      <FixedFooter>
        <Button variant="secondary" onClick={window.close}>
          Cerrar
        </Button>
      </FixedFooter>
    </main>
  );
};

export default TermsConditions;
