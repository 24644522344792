import { combineReducers } from 'redux';

import auth from './auth/store/reducer';
import cars from './cars/store/reducer';
import policy from './dashboard/policy/store/reducer';
import shared from './shared/header/store/reducer';
import analytics from './shared/analytics/reducer';
import context from './shared/context/reducer';

const rootReducer = combineReducers({
  auth,
  policy,
  cars,
  shared,
  analytics,
  context,
});

export default rootReducer;
