import React from 'react';
import PropTypes from 'prop-types';
import RequestEmail from './RequestEmail';
import { AuthService } from './AuthService';
import { textType } from './propTypes';

export const ForgotPassword = ({ config, texts, onBackClick, urlToRedirect, onSuccess }) => (
  <RequestEmail
    config={config}
    sendEmail={(value) => AuthService.sendPasswordResetEmail(value, urlToRedirect)}
    texts={texts}
    onBackClick={onBackClick}
    onSuccess={onSuccess}
  />
);

ForgotPassword.propTypes = {
  config: PropTypes.shape({
    whatsapp: PropTypes.string.isRequired,
    customerSupport: PropTypes.string.isRequired,
  }).isRequired,
  texts: PropTypes.shape({
    tips: PropTypes.object,
    form: PropTypes.object,
    forgotUser: textType,
    forgotEmail: PropTypes.string,
    submitButton: PropTypes.string,
    success: PropTypes.string,
    alert: PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.string,
      registered: PropTypes.shape({
        title: PropTypes.string,
        button: PropTypes.string,
      }),
      sent: PropTypes.string,
    }),
    apiErrorMsg: PropTypes.string,
  }),
  onBackClick: PropTypes.func.isRequired,
  urlToRedirect: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
