/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ForgotPassword } from '../shared/auth';
import { minHeight } from '../assets/styles';
import history from '../history';
import BackgroundShapes from '../shared/components/BackgroundShapes';
import { PublicHeader } from '../shared/header/PublicHeader';
import PublicFooter from '../shared/footer/PublicFooter';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { loadUser } from './store/actions';

const styles = {
  ...minHeight,
  paddingTop: 24,
  'h3.title': {
    marginTop: 0,
  },
};

const InnerAgForgotPassword = ({ menuClose }) => {
  const { t } = useSelfServiceTranslation();

  const onBackClick = () => history.push(t('routes.auth.login'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = {
    tips: {
      title: t('forgotPassword.tips.title'),
      tip1: t('forgotPassword.tips.tip1'),
      tip2: t('forgotPassword.tips.tip2'),
      tip3: {
        first: t('forgotPassword.tips.tip3.first'),
        second: t('forgotPassword.tips.tip3.second'),
      },
      tip4: {
        first: t('forgotPassword.tips.tip4.first'),
        second: t('forgotPassword.tips.tip4.second'),
        third: t('forgotPassword.tips.tip4.third'),
      },
    },

    form: {
      title: t('forgotPassword.form.title'),
      topText: t('forgotPassword.form.topText'),
      email: {
        errorFormat: t('forgotPassword.form.email.errorFormat'),
        errorMandatory: t('forgotPassword.form.email.errorMandatory'),
        label: t('forgotPassword.form.email.label'),
        info: t('forgotPassword.form.email.info'),
      },
    },
    forgotUser: {
      title: t('forgotPassword.forgotUser.title'),
      topText: t('forgotPassword.forgotUser.description'),
    },
    forgotEmail: t('forgotPassword.forgotEmail'),
    submitButton: t('forgotPassword.submitButton'),
    success: t('forgotPassword.success'),

    alert: {
      title: t('forgotPassword.alert.title'),
      button: t('forgotPassword.alert.button'),
      registered: {
        title: t('forgotPassword.alert.registered.title'),
        button: t('forgotPassword.alert.registered.button'),
      },
      sent: t('forgotPassword.alert.sent'),
    },
    apiErrorMsg: t('forgotPassword.apiErrorMsg'),
  };

  /** @note :userId and :token params will be added to the urlToRedirect by 123auth logic later when invoking the sender */
  const urlToRedirect = `${window.location.origin}${t('routes.auth.partialRestorePassword')}`;

  const pushSendOk = () => history.push(t('routes.auth.forgotPassword'));

  return (
    <section>
      <PublicHeader menuClose={menuClose} />
      <section css={styles}>
        <BackgroundShapes />
        <ForgotPassword
          texts={texts}
          config={{
            whatsapp: t('attention.whatsapp'),
            customerSupport: t('attention.customerSupport'),
          }}
          onBackClick={onBackClick}
          urlToRedirect={urlToRedirect}
          onSuccess={pushSendOk}
        />
      </section>
      <PublicFooter />
    </section>
  );
};

InnerAgForgotPassword.propTypes = {
  menuClose: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  menuClose: state.shared.menuClose,
});

export const AgForgotPassword = connect(mapStateToProps, { loadUser })(InnerAgForgotPassword);
