import { Country } from '../shared/country.enum';
import { PaymentTypes } from './payment-method-types.enum';

export const validatePaymentForm = (values, messages) => {
  let errors = {};
  if (!values.type.type) {
    errors.type = messages.typeRequired;
    return errors;
  }

  const {
    country,
    type,
    cardNumber,
    accountNumber,
    dueDate,
    cardHolder,
    legalIdType,
    holderLegalId,
  } = values;

  if (!cardHolder) {
    errors.cardHolder = messages.cardHolderRequired;
  } else if (cardHolder && !validCardHolder(cardHolder)) {
    errors.cardHolder = messages.cardHolderInvalid;
  }
  if (!legalIdType) {
    errors.legalIdType = messages.legalIdTypeRequired;
  }
  if (!holderLegalId) {
    errors.holderLegalId = messages.holderLegalIdRequired;
  }
  if (
    holderLegalId &&
    legalIdType &&
    !validHolderLegalId(legalIdType.name.toUpperCase(), holderLegalId)
  ) {
    errors.holderLegalId = messages.holderLegalIdInvalid;
  }

  if (type.type === PaymentTypes.CREDIT_CARD) {
    if (!cardNumber) {
      errors.cardNumber = messages.cardNumberRequired;
    } else if (cardNumber && !validCardNumber(cardNumber)) {
      errors.cardNumber = messages.cardNumberInvalid;
    }
    if (!dueDate) {
      errors.dueDate = messages.dueDateRequired;
    } else if (dueDate && !validDueDate(dueDate)) {
      errors.dueDate = messages.dueDateInvalid;
    }
  } else if (type.type === PaymentTypes.BANK_ACCOUNT) {
    if (!validAccountNumber(accountNumber, country)) {
      errors.accountNumber = messages.accountNumberInvalid;
    }
  }

  return errors;
};

const validCardNumber = (value) => {
  const cardNumberLength = value.replace(/[^\d]/g, '').length;
  return cardNumberLength >= 13 && cardNumberLength <= 19;
};

const validDueDate = (value) => {
  return /(0[123456789]|10|11|12)[\\/]([0-9][0-9])/.test(value);
};

const validCardHolder = (value) => {
  return value.length >= 4 && !/\d/.test(value);
};

const validHolderLegalId = (type, value) => {
  if (type === 'RUT') {
    return /^(\d{7,8}-[\dkK])$/.test(value);
  }

  if (type === 'DNI') {
    return /^(\d{7,9})$/.test(value);
  }

  if (type === 'CUIT') {
    return /^(\d{10,12})$/.test(value);
  }

  if (type === 'CPF') {
    const cpf = value.replace(/[^\d]/g, '');
    return cpf.length === 11;
  }

  if (type === 'RFC') {
    return true;
  }

  return false;
};

const validAccountNumber = (accountNumber, country) => {
  const numberLength = accountNumber.replace(/[^\d]/g, '').length;
  const clientCountry = country.toLowerCase();

  return (
    (clientCountry === Country.AR && numberLength === 22) ||
    (clientCountry === Country.CL && numberLength >= 5 && numberLength <= 19) ||
    (clientCountry === Country.BR && numberLength > 0) ||
    (clientCountry === Country.MX && numberLength > 0)
  );
};
