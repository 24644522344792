/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { colors } from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import config from '../../../config';

const styles = {
  backgroundColor: colors.green.lightest,
  '.inspection-card-title': {
    color: colors.green.dark,
  },
};

export const InspectionApproved = () => {
  const { t } = useSelfServiceTranslation();

  return (
    <div className="inspection-card" css={styles}>
      <img
        src={`${config.brandingS3URL}/images/img_inspections-success.svg`}
        alt="img_inspection"
      />
      <div>
        <p className="inspection-card-title">
          {t('productSelection.card.inspection.approvedCard.title')}
          <span className="inspection-card-description">
            {t('productSelection.card.inspection.approvedCard.description')}
          </span>
        </p>
      </div>
    </div>
  );
};
