import { makeMeColumn, makeMeContainer, respondTo } from '@123-front/ui-kit';

export const authContainer = {
  ...makeMeContainer(360),
  ...makeMeColumn(1, 1, 32),
  [respondTo('lg')]: {
    ...makeMeContainer(392),
    ...makeMeColumn(1, 1, 32),
  },
};
