import { makeMeColumn, makeMeContainer, respondTo } from '@123-front/ui-kit';

export const modernContainer = (maxWidth) => ({
  maxWidth: maxWidth,
  paddingRight: 16,
  paddingLeft: 16,
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
});

export const container = {
  ...makeMeContainer(360),
  ...makeMeColumn(1, 1, 32),
  [respondTo('lg')]: {
    ...makeMeContainer(392),
  },
};

export const minHeight = {
  minHeight: 'calc(100vh - 265px)',
  [respondTo('xs', 'md')]: { minHeight: 'calc(100vh - 533px)' },
};
