/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { colors, Icon, icons } from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { SelectAppModal } from '../../onboarding/components/SelectAppModal';
import config from '../../../config';

const styles = {
  backgroundColor: colors.red.lightest,
  '.inspection-card-content': {
    flex: 1,
    display: 'flex',
  },
  '.inspection-card-title': {
    color: colors.red.dark,
  },
};

export const InspectionToInspectCard = ({ manualLink }) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useSelfServiceTranslation();

  const onShowModal = () => {
    setShowModal(true);
  };
  const onHideModal = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <button onClick={onShowModal} className="inspection-card" css={styles}>
        <img
          src={`${config.brandingS3URL}/images/img_inspections-danger.svg`}
          alt="img_inspection"
        />
        <div className="inspection-card-content">
          <p className="inspection-card-title">
            {t('productSelection.card.inspection.toInspectCard.title')}
            <span className="inspection-card-description">
              {t('productSelection.card.inspection.toInspectCard.description')}
            </span>
          </p>
        </div>
        <Icon icon={icons.chevronRight} size="24" color={colors.cyan.dark} />
      </button>
      <SelectAppModal show={showModal} onClose={onHideModal} manualLink={manualLink} />
    </React.Fragment>
  );
};

InspectionToInspectCard.propTypes = {
  manualLink: PropTypes.string,
};
