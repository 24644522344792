import * as actions from './action-types';

const INITIAL_STATE = {
  documents: [],
  loadingDocuments: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.DOCUMENTS_DOWNLOAD:
      return {
        ...state,
        loadingDocuments: true,
      };
    case actions.DOCUMENTS_DOWNLOAD_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        loadingDocuments: false,
      };
    case actions.DOCUMENTS_DOWNLOAD_FAIL:
      return {
        ...state,
        loadingDocuments: false,
      };
    default:
      return state;
  }
};
