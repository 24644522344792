/** @jsxRuntime classic */
/** @jsx jsx */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, bodyFont, colors, makeMeColumn, subtitleFont } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { container } from '../../assets/styles';
import { userPropTypes } from '../../auth/store/reducer-proptypes';
import history from '../../history';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';

const styles = {
  textAlign: 'center',
  '.content': {
    ...container,
    ...makeMeColumn(1, 1, 32),
    paddingTop: 42,
    paddingBottom: 60,
  },
  '.title': {
    ...subtitleFont('lg', 'sans'),
    color: colors.gray.darkest,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  '.description': {
    ...bodyFont('sm', 'sans'),
    marginBottom: 24,
  },
  img: {
    width: 150,
  },
};

export const InnerContactSuccess = ({ profile, match }) => {
  const { t } = useSelfServiceTranslation();

  const goToConsult = () => {
    history.push(t('routes.dashboard.contact', { plate: match.params.plate }));
  };

  return (
    <div css={styles}>
      <SelfmanagmentNavbar plate={match.params.plate} activeTab={NavtabId.CONTACT} />
      <div className="content">
        <img
          src="https://branding.s3-us-west-2.amazonaws.com/images/animations/animation_success.gif"
          alt="success"
        />
        <h1 className="title">{t('contact.success.title')}</h1>
        <p className="description">{t('contact.success.description', { email: profile.email })}</p>
        <Button
          block
          color="interactive"
          size="md"
          type="submit"
          variant="secondary"
          onClick={goToConsult}
        >
          {t('contact.success.button')}
        </Button>
      </div>
    </div>
  );
};

InnerContactSuccess.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      plate: PropTypes.string.isRequired,
    }),
  }).isRequired,
  profile: userPropTypes.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    plate: ownProps.match.params.plate,
    profile: state.auth.user,
  };
};

export const ContactSuccess = connect(mapStateToProps)(InnerContactSuccess);
