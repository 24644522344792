/* eslint-disable react/jsx-curly-newline */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import AppContainer from '../shared/components/AppContainer';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { t } = useSelfServiceTranslation();

  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.token ? (
          <Redirect push to={t('routes.auth.login')} />
        ) : (
          <AppContainer>
            <Component {...props} />
          </AppContainer>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default connect(null)(PrivateRoute);
