/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, image, favicons, manifest }) => {
  return (
    <Fragment>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {image && <meta property="og:image" content={image} />}
        {manifest && <link rel="manifest" href={manifest} />}
      </Helmet>
      {favicons && (
        <Helmet>
          <link rel="shortcut icon" href={favicons.faviconIco} type="image/x-icon" />
          <link rel="apple-touch-icon" href={favicons.favicon152} sizes="152x152" />
          <link rel="apple-touch-icon" href={favicons.favicon180} sizes="180x180" />
          <link rel="icon" type="image/png" href={favicons.favicon16} sizes="16x16" />
          <link rel="icon" type="image/png" href={favicons.favicon32} sizes="32x32" />
        </Helmet>
      )}
    </Fragment>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  favicons: PropTypes.object,
  manifest: PropTypes.string,
};

export default SEO;
