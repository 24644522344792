/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import {
  Column,
  Container,
  Row,
  colors,
  respondTo,
  titleFont,
  icons,
  Icon,
  box,
} from '@123-front/ui-kit';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { userPropTypes } from '../../auth/store/reducer-proptypes';
import { carPropTypes } from '../../cars/store/reducer-proptypes';
import { findCarByPlate } from '../../cars/car.utils';
import { countryDomain } from '../../shared/domainResolver';
import config from '../../config';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { InspectionCard } from './components/InspectionCard';
import { CardInsurance } from './components/CardInsurance';

const baseUrl = `${config.baseUrl}.${countryDomain}`;

const style = {
  '.card-wrapper': {
    maxWidth: 328,
    margin: '0 auto',
    [respondTo('xs', 'md')]: {
      margin: '40px auto 0',
    },

    [respondTo('md')]: {
      maxWidth: 360,
    },
    '.inspection-card': {
      marginBottom: 24,
    },
    '.chevron': {
      transform: 'rotate(270deg)',
      width: 16,
      height: 16,
    },
  },

  '.title': {
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
    fontWeight: 'bold',
    marginBottom: 32,
    marginTop: 32,
    textAlign: 'center',
  },

  '.doubts': {
    maxWidth: 328,
    [respondTo('md')]: {
      maxWidth: 360,
    },
    margin: '0 auto',
    marginBottom: 80,
    '.links': {
      ...box,
      '.link': {
        position: 'relative',
        display: 'block',
        padding: '14px 40px 14px 16px',
        border: box.border,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        '&:last-child': {
          borderBottom: 0,
        },
        textAlign: 'left',
        transition: 'background 0.2s',
        '.icon-chevron': {
          position: 'absolute',
          right: 8,
          top: '50%',
          marginTop: -12,
        },
        '&:hover': {
          background: colors.cyan.lightest,
        },
      },
    },
  },
};

const InnerInsurance = ({ car, match }) => {
  const { t } = useSelfServiceTranslation();
  return (
    <div>
      {car && (
        <div>
          <SelfmanagmentNavbar plate={car.plate} activeTab={NavtabId.DASHBOARD} />
          <Container>
            <Row>
              <Column>
                <div css={style}>
                  <div className="card-wrapper">
                    <CardInsurance
                      coverageDescription={car.coverageDescription}
                      plate={car.plate}
                      model={car.model}
                      brand={car.brand}
                      year={car.year}
                      policyNumber={car.policyNumber}
                      expiration={car.expiration}
                      company={{
                        companyId: car.company.companyId,
                        name: car.company.name,
                      }}
                    />
                  </div>

                  <InspectionCard
                    status={car.inspection?.status}
                    manualLink={car.inspection?.manual}
                  />

                  <div className="doubts">
                    <h3 className="title">{t('doubts.title')}</h3>

                    <div className="links">
                      <a
                        className="link"
                        href={t('routes.external.faq', { baseUrl })}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('doubts.checkThisInfo')}</span>
                        <Icon
                          className="icon-chevron"
                          icon={icons.chevronRight}
                          size="24"
                          color={colors.cyan.dark}
                        />
                      </a>

                      <Link
                        className="link"
                        to={t('routes.dashboard.contact', { plate: car.plate })}
                      >
                        <span>{t('doubts.sendUsConsult')}</span>
                        <Icon
                          className="icon-chevron"
                          icon={icons.chevronRight}
                          size="24"
                          color={colors.cyan.dark}
                        />
                      </Link>

                      <a
                        className="link"
                        href={t('routes.external.contactUs', { baseUrl })}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{t('doubts.contactUs')}</span>
                        <Icon
                          className="icon-chevron"
                          icon={icons.chevronRight}
                          size="24"
                          color={colors.cyan.dark}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

InnerInsurance.propTypes = {
  profile: userPropTypes.isRequired,
  car: carPropTypes,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  car: findCarByPlate(state.cars.cars, ownProps.match.params.plate),
  profile: state.auth.user,
});

export const Circulation = connect(mapStateToProps)(InnerInsurance);
