import PropTypes from 'prop-types';
import { InspectionStatus } from '../../shared/inspection.enum';

export const companyPropTypes = PropTypes.shape({
  _id: PropTypes.string,
  companyId: PropTypes.string,
  name: PropTypes.string.isRequired,
  phones: PropTypes.arrayOf(PropTypes.string),
  whatsapp: PropTypes.string,
  contactSchedule: PropTypes.string.isRequired,
  sinisterFormLink: PropTypes.string.isRequired,
  contactAssistance: PropTypes.shape({
    phones: PropTypes.arrayOf(PropTypes.string),
    schedule: PropTypes.string,
    reportLink: PropTypes.string,
    mobileAppLink: PropTypes.shape({
      android: PropTypes.string,
      ios: PropTypes.string,
    }),
  }),
});

export const inspectionPropTypes = PropTypes.shape({
  status: PropTypes.oneOf(Object.values(InspectionStatus)),
  manual: PropTypes.string,
  tireRim: PropTypes.bool,
  tire: PropTypes.bool,
  specialRim: PropTypes.bool,
});

export const carPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  plate: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
  coverageDescription: PropTypes.string.isRequired,
  hasCng: PropTypes.bool.isRequired,
  company: companyPropTypes.isRequired,
  inspection: inspectionPropTypes,
});

export const carsPropTypes = PropTypes.arrayOf(carPropTypes);
