import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { Alert, Button } from '@123-front/ui-kit';
/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  '.token-error': {
    marginTop: 24,
    marginBottom: 24,
  },
};

export const TokenError = ({ title, msg, onEnterTo123, goToLogin }) => (
  <div css={styles}>
    <h3 className="title">{title}</h3>
    <Alert text={msg} type="error" className="token-error" dismissible={false} />
    <Button block variant="secondary" color="interactive" onClick={onEnterTo123}>
      {goToLogin}
    </Button>
  </div>
);

TokenError.propTypes = {
  title: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  onEnterTo123: PropTypes.func.isRequired,
  goToLogin: PropTypes.string.isRequired,
};
