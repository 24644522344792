const { getCountryAndBaseDomain } = require('../shared/domainResolver');

const countryAndBase = getCountryAndBaseDomain(window.location.hostname.split('.'));
module.exports = {
  applicationId: '5dee53a448df69549d5e83f6',
  attention: {
    customerSupport: 'clientes.argentina@123seguro.com',
    phone: '0800-3456-123',
    whatsapp: '+54 9 11 5902 2631',
  },
  brandingS3URL: 'https://branding.s3.us-west-2.amazonaws.com',
  services: {
    s3: 'http://123file-ws.123seguro.com:3000',
  },
  termsLink: 'https://www.123seguro.com.ar/terminos-y-condiciones',
  gtmIds: {
    ar: '',
    cl: '',
    co: '',
    br: '',
  },
  hotjar: null,
  baseUrl: `http://123seguro.${countryAndBase.tld}${
    countryAndBase.localhost ? '.localhost' : ''
  }:3000`,
  basePath: `${process.env.REACT_APP_PATH}`,
};
