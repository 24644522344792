/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Collapsible } from '@123-front/ui-kit';
import Item from './ItemMenu';

const stlyes = {
  '.sub-menu': {
    button: {
      paddingRight: 12,
      '.title': {
        textTransform: 'capitalize',
      },
    },
    a: {
      borderLeft: 'none',
      marginLeft: 16,
    },
  },
};

const CollapsibleHeader = ({ items, title, menuType, className }) => {
  return (
    <Collapsible css={stlyes} type={menuType} title={title} className={className}>
      {items &&
        items.map((subItem, i) =>
          subItem.items ? (
            <CollapsibleHeader
              key={subItem.title.concat(i)}
              {...subItem}
              menuType="submenu"
              className="sub-menu"
            />
          ) : (
            <Item key={subItem.title.concat(i)} {...subItem} />
          ),
        )}
    </Collapsible>
  );
};

CollapsibleHeader.defaultProps = {
  items: '',
  className: '',
};

CollapsibleHeader.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  title: PropTypes.string.isRequired,
  menuType: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CollapsibleHeader;
