/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { colors } from '@123-front/ui-kit';
import PropTypes from 'prop-types';

const footerStyles = {
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  gap: '24px',
  padding: 16,
  backgroundColor: colors.gray.ultralight,
  boxShadow: 'rgb(0 0 0 / 10%) 0px -4px 20px',
};

export const FixedFooter = ({ children }) => <div css={footerStyles}>{children}</div>;

FixedFooter.propTypes = {
  children: PropTypes.node,
};
