/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Icon, icons, colors } from '@123-front/ui-kit';
import { Link } from 'react-router-dom';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';

const styles = {
  display: 'none',
  flexDirection: 'row',
  alignItems: 'flex-end',

  '.link': {
    marginLeft: 8,
  },
};

export function WebAccess({ className = '' }) {
  const { t } = useSelfServiceTranslation();

  const to = t('routes.auth.login');

  return (
    <div className={className} css={styles}>
      <Icon icon={icons.device} size="20" color={colors.cyan.dark} />
      <Link className="link" to={`${to}?utm_source=email&utm_medium=onboarding-inspections`}>
        {t('landings.downloadApp.webAccess')}
      </Link>
    </div>
  );
}

WebAccess.propTypes = {
  className: PropTypes.string,
};
