import { box, colors, respondTo, subtitleFont } from '@123-front/ui-kit';

export const cardStyles = {
  ...box,
  margin: 5,
  '.link, .container, .card-details': {
    display: 'flex',
    alignItems: 'center',
  },
  '.card-title::first-letter': {
    textTransform: 'uppercase',
  },
  '.link': {
    height: 100,
    justifyContent: 'space-around',
    textDecoration: 'none',
  },
  '.container': {
    width: 280,
    marginLeft: 20,
  },
  '.card-logo': {
    width: 100,
  },
  '.card-details': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 24,
  },
  '.card-title': {
    ...subtitleFont('md', 'sans'),
    color: colors.gray.darkest,
    marginTop: 0,
    marginBottom: 0,
  },
  '.icon': {
    marginRight: 8,
    width: 24,
  },
  [respondTo('sm')]: {
    '.container': {
      width: 320,
    },
  },
};
