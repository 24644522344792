/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Icon } from '@123-front/ui-kit';
import envConfig from '../../../config';
import footerIcons from '../../icons';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';

const styles = {
  display: 'flex',
  alignItems: 'center',

  '.logo': {
    height: 40,
    marginRight: 16,
  },
  '.social': {
    marginLeft: 'auto',
    a: { padding: '0 8px' },
  },
};

const FooterTop = () => {
  const { t } = useSelfServiceTranslation();

  return (
    <div css={styles}>
      <a href={t('footer.top.home')} title={t('123SeguroTitle')}>
        <img
          className="logo"
          src={`${envConfig.brandingS3URL}/logotypes/123/color/logo_123.svg`}
          alt={t('123SeguroTitle')}
          title={t('123SeguroTitle')}
        />
      </a>
      <nav className="social">
        <a
          href="https://www.facebook.com/123seguro"
          aria-label="Facebook 123seguro"
          target="_brank"
        >
          <Icon icon={footerIcons.facebook} color="#2a81ea" size={24} />
        </a>
        <a
          href="https://www.instagram.com/123seguro"
          aria-label="Instagram 123seguro"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={footerIcons.brandInstagram} color="#de3c79" size={24} />
        </a>
        <a
          href="https://twitter.com/123seguro"
          aria-label="Twitter 123seguro"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={footerIcons.brandTwitter} color="#1da1f2" size={24} />
        </a>
        <a
          href="https://www.youtube.com/user/123SEGUROcom/"
          aria-label="Youtube 123seguro"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={footerIcons.brandYoutube} color="#ff0000" size={24} />
        </a>
        <a
          href="https://www.linkedin.com/company/123seguro"
          aria-label="Linkedin 123seguro"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={footerIcons.brandLinkedin} color="#0073b1" size={24} />
        </a>
        <a
          href="https://blog.123seguro.com"
          aria-label="Blog 123seguro"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={footerIcons.brandBlogs} color="#ffa000" size={24} />
        </a>
      </nav>
    </div>
  );
};

export default FooterTop;
