/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import { colors } from '@123-front/ui-kit';
import { authReducerPropTypes } from '../../../auth/store/reducer-proptypes';
import { carsPropTypes } from '../../../cars/store/reducer-proptypes';
import { domainLocation } from '../../domainResolver';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { menuItems } from './pathsFactory';
import CollapsibleHeader from './CollapsibleMenu';
import Item from './ItemMenu';

const style = {
  a: {
    fontSize: 13,
    color: colors.gray.dark,
    width: '100%',
    padding: '12px 40px 12px 24px',
    borderLeft: `2px solid ${colors.cyan.dark}`,
    margin: '2px 0',
  },
  '.menu-link': {
    paddingRight: 12,
    paddingLeft: 16,
  },
};

const BodyMenu = ({ auth, cars }) => {
  const { t } = useSelfServiceTranslation();
  const items = menuItems(cars, domainLocation, auth.isAuthenticated, t);
  return (
    <div css={style}>
      {items.map((item, i) =>
        item.path || item.externalPath ? (
          <Item key={item.title.concat(i)} {...item} className="menu-link" />
        ) : (
          <CollapsibleHeader key={item.title.concat(i)} {...item} menuType="menu" />
        ),
      )}
    </div>
  );
};

BodyMenu.propTypes = {
  auth: authReducerPropTypes.isRequired,
  cars: carsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cars: state.cars.cars,
});

export default connect(mapStateToProps)(BodyMenu);
