/** @jsxRuntime classic */
/** @jsx jsx */
import { bodyFont } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const style = {
  ...bodyFont('lg'),
};

export const PaymentMethodNumber = ({ color, value }) => {
  return <div css={{ ...style, color }}>{value}</div>;
};

PaymentMethodNumber.propTypes = {
  color: PropTypes.string,
  value: PropTypes.string,
};
