/** @jsxRuntime classic */
/** @jsx jsx */
import { base, colors, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { BankIssuer } from './BankIssuer';
import { HolderDetail } from './HolderDetail';
import { PaymentMethodBox } from './PaymentMethodBox';
import { PaymentMethodNumber } from './PaymentMethodNumber';

const fontColor = 'black';

const styles = {
  marginBottom: 15,
  marginRight: 15,

  [respondTo('xs', 'md')]: {
    marginRight: 0,
  },

  flex: '3 0 10px',
  '.bank-account-issuer-state': {
    paddingBottom: 10,
  },
  '.bank-account-issuer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '.bank-account': {
    fontFamily: base.fontFamily,
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: colors.gray.dark,
  },
  '.holder-details': {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    justifyContent: 'space-between',
  },
  '.bank-account-box': {
    background: colors.gray.ultralight,
    border: `1px solid ${colors.gray.light}`,
  },
};

export const BankAccountDetails = ({
  issuer,
  number,
  holderName,
  holderIdType,
  holderIdNumber,
}) => {
  const { t, ready } = useSelfServiceTranslation();

  return ready ? (
    <div css={styles}>
      <PaymentMethodBox className="bank-account-box">
        <div className="bank-account-issuer-state">
          <div className="bank-account-issuer">
            <span className="bank-account">cuenta de banco</span>
            <BankIssuer color="gray" name={issuer} />
          </div>
        </div>
        <PaymentMethodNumber color={fontColor} value={number} />
        <div className="holder-details">
          <HolderDetail
            title={t('paymentMethods.createPaymentMethod.holderName')}
            value={holderName}
            color={fontColor}
          />
          <HolderDetail title={holderIdType} value={holderIdNumber} color={fontColor} />
        </div>
      </PaymentMethodBox>
    </div>
  ) : null;
};

BankAccountDetails.propTypes = {
  issuer: PropTypes.string,
  number: PropTypes.string,
  holderName: PropTypes.string,
  holderIdType: PropTypes.string,
  holderIdNumber: PropTypes.string,
};
