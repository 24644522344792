/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { respondTo } from '@123-front/ui-kit';
import config from '../../config';

const styles = {
  display: 'none',
  [respondTo('md')]: {
    display: 'block',
    position: 'fixed',
    top: 60,
    width: '100%',
    '.dots, .circles': {
      position: 'absolute',
    },
    '.dots': {
      top: 40,
      left: 0,
    },
    '.circles': {
      top: 0,
      right: 0,
      opacity: 0.5,
    },
  },
};

const BackgroundTexture = () => {
  return (
    <section css={styles}>
      <img
        className="dots"
        src={`${config.brandingS3URL}/miscellaneous/pattern-dots-home-nosotros.svg`}
        alt="pattern"
      />
      <img
        className="circles"
        src={`${config.brandingS3URL}/miscellaneous/misc-deco-insurers.svg`}
        alt="pattern"
      />
    </section>
  );
};

export default BackgroundTexture;
