import config from '../../../config';
import { arInsurers, clInsurers, coInsurers } from './insurersList';

export const menuItems = (cars, country, logged, t) => {
  const globalPath = config.basePath;

  const base = {
    ar: {
      dom: '.com.ar',
      quote: [
        {
          title: t('bodyMenu.quote.car'),
          externalPath: 'https://123seguro.com.ar/seguros/auto/cotizar/marca',
        },
        {
          title: t('bodyMenu.quote.life'),
          externalPath: 'https://123seguro.com.ar/seguros/vida',
        },
        {
          title: t('bodyMenu.quote.business'),
          externalPath: 'https://123seguro.com.ar/seguros/comercio',
        },
      ],
      insurers: arInsurers,
    },
    cl: {
      dom: '.cl',
      quote: [
        {
          title: t('bodyMenu.quote.car'),
          externalPath: 'https://123seguro.cl/seguros/automotriz/cotizar/marca',
        },
      ],
      insurers: clInsurers,
    },
    co: {
      dom: '.com.co',
      quote: [
        {
          title: t('bodyMenu.quote.car'),
          externalPath: 'https://123seguro.com.co/seguros/carro/cotizar/marca',
        },
      ],
      insurers: coInsurers,
    },
    br: {
      dom: '.com.br',
      quote: [
        {
          title: t('bodyMenu.quote.car'),
          externalPath: 'https://123seguro.com.br/seguro/carro/cotar/1/busqueda-patente',
        },
      ],
    },
  };

  return [
    {
      title: t('bodyMenu.quote.title'),
      items: base[country].quote,
    },
    {
      title: t('bodyMenu.myInsurances.title'),
      items:
        logged && cars
          ? [
              ...cars.map((i) => {
                return {
                  title: `${i.brand.toLowerCase()} ${i.model.toLowerCase()}  | ${i.plate.toUpperCase()}`,
                  items: [
                    {
                      title: t('bodyMenu.myInsurances.nav.myInsurance'),
                      path: t('routes.dashboard.circulation', { plate: i.plate }),
                    },
                    {
                      title: t('bodyMenu.myInsurances.nav.documentation'),
                      path: t('routes.dashboard.policy', { plate: i.plate }),
                    },
                    {
                      title: t('bodyMenu.myInsurances.nav.reportClaim'),
                      path: t('routes.dashboard.claimCrash', { plate: i.plate }),
                    },
                    {
                      title: t('bodyMenu.myInsurances.nav.contact'),
                      path: t('routes.dashboard.contact', { plate: i.plate }),
                    },
                  ],
                };
              }),
              {
                title: t('bodyMenu.myInsurances.nav.myAccount'),
                path: t('routes.user.myAccount'),
              },
            ]
          : [
              {
                title: t('bodyMenu.myInsurances.nav.login'),
                path: `${globalPath}/acceder2`,
              },
            ],
    },
    {
      title: t('bodyMenu.help.title'),
      items: [
        {
          title: t('bodyMenu.help.nav.faq'),
          items: [
            {
              title: t('bodyMenu.help.nav.aboutUs'),
              externalPath: `https://www.123seguro${base[country].dom}/preguntas-frecuentes/sobre-nosotros`,
            },
            {
              title: t('bodyMenu.help.nav.customerSupport'),
              externalPath: `https://www.123seguro${base[country].dom}/preguntas-frecuentes/centro-de-soluciones`,
            },
            {
              title: t('bodyMenu.help.nav.documentation'),
              externalPath: `https://www.123seguro${base[country].dom}/preguntas-frecuentes/documentacion-y-bajas`,
            },
          ],
        },
        {
          title: t('bodyMenu.help.nav.contact'),
          externalPath: `https://www.123seguro${base[country].dom}/contacto`,
        },
      ],
    },
    {
      title: t('bodyMenu.aboutUs.title'),
      items: [
        {
          title: t('bodyMenu.aboutUs.nav.home'),
          externalPath: `https://www.123seguro${base[country].dom}`,
        },
        {
          title: t('bodyMenu.aboutUs.nav.aboutUs'),
          externalPath: `https://www.123seguro${base[country].dom}/nosotros`,
        },
        {
          title: t('bodyMenu.aboutUs.nav.insurances'),
          items: base[country].insurers,
        },
        {
          title: t('bodyMenu.aboutUs.nav.socialMedia'),
          items: [
            {
              title: 'Facebook',
              externalPath: 'https://www.facebook.com/123seguro',
            },
            {
              title: 'Instagram',
              externalPath: 'https://www.instagram.com/123seguro/',
            },
            {
              title: 'Twitter',
              externalPath: 'https://twitter.com/123seguro',
            },
            {
              title: 'Youtube',
              externalPath: 'https://www.youtube.com/user/123SEGUROcom/',
            },
            {
              title: 'LinkedIn',
              externalPath: 'https://www.linkedin.com/company/123seguro/',
            },
          ],
        },
      ],
    },
    {
      title: 'Blog',
      externalPath: 'https://blog.123seguro.com/',
    },
  ];
};
