/** @jsxRuntime classic */
/** @jsx jsx */
import { bodyFont } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';

export const BankIssuer = ({ color, name }) => {
  const { t, ready } = useSelfServiceTranslation();

  return name ? (
    <img
      css={{ height: 28 }}
      src={`http://branding.s3.us-west-2.amazonaws.com/logotypes/payments/${color}/bank_${name}.svg`}
      alt={name}
    />
  ) : ready ? (
    <span css={{ ...bodyFont('sm'), color: '#FFFFFF' }}>
      {t('paymentMethods.createPaymentMethod.issuer')}
    </span>
  ) : null;
};

BankIssuer.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
};
