import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Collapsible, colors, titleFont } from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { modernContainer } from '../../../assets/styles';
import { UserNotFoundReasons } from './UserNotFoundReasons';
import { UserNotFoundChannels } from './UserNotFoundChannels';

const styles = {
  ...modernContainer(552),

  '.illustration': {
    widt: '100%',
    textAlign: 'center',
    img: {
      marginTop: 40,
      marginBottom: 32,
      width: 165,
    },
  },

  '.user-not-found-title': {
    ...titleFont('md', 'serif'),
    textAlign: 'center',
  },

  '.collapsibles': {
    '.collapsible': {
      '&:last-of-type': {
        borderBottom: `1px solid ${colors.gray.lighten}`,
      },
      '> button': {
        paddingRight: 40,
      },
    },
  },

  '.logout-container': {
    width: '100%',
    textAlign: 'center',
    marginTop: 60,
    marginBottom: 60,
  },
};

export const UserNotFound = ({ className = '', signOut }) => {
  const { t } = useSelfServiceTranslation();
  const onSignOut = () => signOut(t('routes.auth.login'));

  return (
    <div css={styles} className={className}>
      <div className="illustration">
        <img
          alt="illustration"
          src="https://branding.s3.us-west-2.amazonaws.com/illustration/illus_empty.svg"
        />
      </div>
      <h2 className="user-not-found-title">
        {t('userNotFound.title', { mail: localStorage.getItem('mail') })}
      </h2>
      <div className="collapsibles">
        <Collapsible className="collapsible" type="list" title={t('userNotFound.reason.title')}>
          <UserNotFoundReasons />
        </Collapsible>
        <Collapsible className="collapsible" type="list" title={t('userNotFound.channels.title')}>
          <UserNotFoundChannels />
        </Collapsible>
      </div>

      <div className="logout-container">
        <Button type="button" onClick={onSignOut} variant="secondary" color="danger" size="sm">
          {t('account.logout')}
        </Button>
      </div>
    </div>
  );
};

UserNotFound.propTypes = {
  signOut: PropTypes.func.isRequired,
  className: PropTypes.string,
};
