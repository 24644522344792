import PropTypes from 'prop-types';
import {
  colors,
  Divider,
  getPhoneLink,
  getWhatsappLink,
  Icon,
  icons,
  bodyFont,
  box,
  respondTo,
  titleFont,
} from '@123-front/ui-kit';
import { useState } from 'react';
import { jsx } from '@emotion/core';
import config from '../../../config';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
/** @jsxRuntime classic */
/** @jsx jsx */

import { carPropTypes } from '../../../cars/store/reducer-proptypes';

const renderInsurancePhones = (phones) => {
  return phones.map((phone) => (
    <a key={phone} href={getPhoneLink(phone)}>
      {phone}
    </a>
  ));
};

const styles = {
  paddingTop: 16,
  [respondTo('md')]: { display: 'none' },
  '.cards': {
    margin: '0 -8px',
  },
  '.flip-card': {
    width: 156,
    height: 100,
    display: 'inline-block',
    marginLeft: 8,
    marginRight: 8,
    perspective: '1000px',
    textAlign: 'center',
    cursor: 'pointer',
    ...bodyFont('sm'),
    '.icon': {
      width: 60,
      height: 60,
    },
  },
  '.flip-card-inner': {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'transform 0.8s',
    transformStyle: 'preserve-3d',
  },
  '.flip-card.flipped .flip-card-inner': { transform: 'rotateY(180deg)' },
  '.flip-card-front, .flip-card-back': {
    position: 'absolute',
    padding: 8,
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
  },
  '.flip-card-front': {
    ...box,
  },
  '.flip-card-back': {
    ...box,

    transform: 'rotateY(180deg)',
  },
  '.ambulance': { padding: 16, p: { margin: 0 }, a: { display: 'inline-block', padding: 8 } },
  '.car-help': {
    textAlign: 'left',
    '.udt-icon': { height: 24, marginRight: 8 },
    '.headset': { height: 32, verticalAlign: 'middle' },
    '.links': { display: 'inline-block', width: 'calc(100% - 24px)', verticalAlign: 'middle' },
    a: { padding: 4 },
    '.udt-divider': { margin: '4px 0' },
  },
  '.cards-title': { ...titleFont('sm', 'serif'), color: colors.gray.darkest, marginBottom: 16 },
};

export const EveryoneIsFine = ({ car, className = '' }) => {
  const { t } = useSelfServiceTranslation();

  const [flipAmbulance, seFlipAmbulance] = useState(false);
  const [flipHelp, seFlipHelp] = useState(false);

  return (
    <div css={styles}>
      <h3 className="cards-title">{t('claimCrash.cards.title')}</h3>
      <div className="cards">
        <div
          className={`flip-card ${flipAmbulance ? 'flipped' : undefined}`}
          onClick={() => seFlipAmbulance(true)}
          onKeyDown={() => seFlipAmbulance(true)}
          role="button"
          tabIndex={0}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img
                className="icon"
                src={`${config.brandingS3URL}/images/img_doctor.svg`}
                alt="Urgencias"
              />
              <p>{t('claimCrash.cards.ambulance.front')}</p>
            </div>
            <div className="flip-card-back ambulance">
              <p>{t('claimCrash.cards.ambulance.back.callTo')}</p>
              <a href={getPhoneLink(t('claimCrash.cards.ambulance.back.number'))}>
                {t('claimCrash.cards.ambulance.back.number')}
              </a>
              <p>{t('claimCrash.cards.ambulance.back.schedule')}</p>
            </div>
          </div>
        </div>
        <div
          className={`flip-card ${flipHelp ? 'flipped' : undefined}`}
          onClick={() => seFlipHelp(true)}
          onKeyDown={() => seFlipHelp(true)}
          role="button"
          tabIndex={0}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img
                className="icon"
                src={`${config.brandingS3URL}/images/img_car-assistance.svg`}
                alt="Grua"
              />
              <p>{t('claimCrash.cards.help.front')}</p>
            </div>

            <div className="flip-card-back car-help">
              <div>
                <Icon icon={icons.headset} className="headset" />
                {car && car.company && (
                  <span className="links">{renderInsurancePhones(car.company.phones)}</span>
                )}
              </div>
              {car && car.company && car.company.whatsapp && (
                <div>
                  <Divider className="divider" />
                  <Icon
                    className="icon-back"
                    icon={icons.whatsapp}
                    size="16"
                    color={colors.gray.dark}
                  />
                  <a
                    href={getWhatsappLink(car.company.whatsapp)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {car.company.whatsapp.replace('+54 9 ', '')}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EveryoneIsFine.propTypes = {
  car: carPropTypes.isRequired,
  className: PropTypes.string,
};
