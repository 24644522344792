import * as actions from './action-types';

const INITIAL_STATE = {
  menuClose: true,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.CLOSE_MENU:
      return {
        ...state,
        menuClose: !state.menuClose,
      };
    default:
      return state;
  }
};
