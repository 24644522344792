import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ButtonSelectorOptions, Modal } from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { CountryOption } from './CountryOption';

export const CountrySelectorModal = ({ show, onClose, className = '' }) => {
  const { t } = useSelfServiceTranslation();

  // habilitar a medida que soporten autogestion
  const options = [
    {
      name: t('countries.ar'),
      flag: 'https://branding.s3.us-west-2.amazonaws.com/flags/flag_argentina.svg',
      link: 'https://123seguro.com.ar/autogestion',
    },
    // {
    //   name: t('countries.br'),
    //   flag: 'https://branding.s3.us-west-2.amazonaws.com/flags/flag_brasil.svg',
    //   link: 'https://123seguro.br/autogestion',
    // },
    {
      name: t('countries.cl'),
      flag: 'https://branding.s3.us-west-2.amazonaws.com/flags/flag_chile.svg',
      link: 'https://123seguro.cl/autogestion',
    },
    // {
    //   name: t('countries.co'),
    //   flag: 'https://branding.s3.us-west-2.amazonaws.com/flags/flag_colombia.svg',
    //   link: 'https://123seguro.com.co/autogestion',
    // },
    // {
    //   name: t('countries.mx'),
    //   flag: 'https://branding.s3.us-west-2.amazonaws.com/flags/flag_mexico.svg',
    //   link: 'https://123seguro.cl/autogestion',
    // },
    // {
    //   name: t('countries.ur'),
    //   flag: 'https://branding.s3.us-west-2.amazonaws.com/flags/flag_uruguay.svg',
    //   link: 'https://123seguro.cl/autogestion',
    // },
  ];

  const redirectToCountry = (option) => {
    window.location.href = option.link;
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      className={className}
      title={t('userNotFound.modal.title')}
    >
      <ButtonSelectorOptions
        items={options}
        render={(country) => <CountryOption country={country} />}
        onClick={redirectToCountry}
      />
    </Modal>
  );
};

CountrySelectorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};
