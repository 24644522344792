/** @jsxRuntime classic */
/** @jsx jsx */
import { baseStyles, normalize } from '@123-front/ui-kit';
import { Global, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import CookieConsenter from './CookieConsenter';
import GoogleTagManager from './GoogleTagManager';
import history from './history';
import './i18n';
import Routes from './routing/routes';
import './shared/axiosInterceptor';
import SEO from './shared/components/SEO';
import { loadContext as loadContextAction } from './shared/context/actions';
import contextReducerPropTypes from './shared/context/reducer.proptypes';
import { useSEOConfig } from './utils/useSEOConfig';

const App = ({ context, loadContext }) => {
  const { didContextLoad, oneTrustId } = context;
  const seoConfig = useSEOConfig();

  useEffect(() => {
    if (!didContextLoad) {
      loadContext();
    }
  }, [didContextLoad, loadContext]);

  const [didCookieConsenterLoad, setDidCookieConsenterLoad] = useState(false);
  const onCookieConsenterLoad = useCallback(() => {
    setDidCookieConsenterLoad(true);
  }, []);

  return (
    <main>
      {seoConfig && (
        <SEO
          title={seoConfig.title}
          description={seoConfig.description}
          image={seoConfig.image}
          favicons={seoConfig.favicons}
          manifest={seoConfig.manifest}
        />
      )}
      {didContextLoad && oneTrustId && (
        <CookieConsenter dataDomainScript={oneTrustId} onLoad={onCookieConsenterLoad} />
      )}
      {((didContextLoad && !oneTrustId) || didCookieConsenterLoad) && <GoogleTagManager />}
      <Global styles={[normalize, baseStyles]} />
      <Router history={history}>
        <Suspense fallback={null}>
          <Switch>
            <Route component={Routes} />
          </Switch>
        </Suspense>
      </Router>
    </main>
  );
};

App.propTypes = {
  context: contextReducerPropTypes.isRequired,
  loadContext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    context: state.context,
  };
};

export default connect(mapStateToProps, { loadContext: loadContextAction })(App);
