/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Alert, Button, box, colors, bodyFont, titleFont } from '@123-front/ui-kit';
import PaswordInput, { NEW_CHECKS, NEW_MIN, NEW_MIN_STRENGTH } from './PasswordInput';

const styles = {
  '.title': {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    margin: '24px 0 0 0',
    textAlign: 'center',
  },
  '.top-text': {
    ...bodyFont('md'),
    marginTop: 12,
    marginBottom: 20,
    textAlign: 'center',
  },
  '.form': {
    ...box,
    padding: '24px 16px',
    '.field': { input: { width: '100%', boxSizing: 'border-box' } },
  },
  '.api-error': {
    marginBottom: 24,
  },
};

export const NewPassword = ({ texts, onSubmit, changePassword, config }) => {
  const [submitActive, setSubmitActive] = useState(true);
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [strength, setStrength] = useState(0);
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  const onChange = (payload) => {
    setErrorMsg('');
    setPassword(payload.val);
    setStrength(payload.strength);
  };

  const isValid = () => {
    if (!password) {
      setErrorMsg(texts.form.password.errorMandatory);
      return false;
    }
    if (strength <= 2) {
      setErrorMsg(texts.form.password.errorFormat);
      return false;
    }
    return true;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    setApiErrorMsg('');
    if (isValid()) {
      setSubmitActive(false);
      let authResponse;
      try {
        authResponse = await changePassword(password);
      } catch (error) {
        setSubmitActive(true);
        return setApiErrorMsg(
          texts.form.errors.unexpected.replaceAll('[[whatsapp]]', config.whatsapp),
        );
      }
      await onSubmit(authResponse.data);
      setSubmitActive(true);
    }
  };

  return (
    <div css={styles}>
      <h3 className="title">{texts.form.title}</h3>
      <p className="top-text">{texts.form.topText}</p>
      <div className="form">
        <PaswordInput
          label={texts.form.password.label}
          value={password}
          className="field"
          error={errorMsg}
          info={texts.form.password.info}
          onChange={onChange}
          onBlur={isValid}
          min={NEW_MIN}
          minStrength={NEW_MIN_STRENGTH}
          checks={NEW_CHECKS}
        />
        {apiErrorMsg && <Alert dismissible text={apiErrorMsg} className="api-error" type="error" />}
        <Button block color="interactive" disabled={!submitActive} onClick={handleSubmit}>
          {texts.form.button}
        </Button>
      </div>
    </div>
  );
};

NewPassword.propTypes = {
  config: PropTypes.shape({
    whatsapp: PropTypes.string,
  }).isRequired,
  texts: PropTypes.shape({
    form: PropTypes.shape({
      title: PropTypes.string,
      topText: PropTypes.string,
      button: PropTypes.string,
      password: PropTypes.shape({
        errorMandatory: PropTypes.string,
        errorFormat: PropTypes.string,
        label: PropTypes.string,
        info: PropTypes.string,
      }),
      errors: PropTypes.shape({
        unexpected: PropTypes.string,
      }),
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};
