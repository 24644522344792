import * as actions from './action-types';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: null,
  userLoaded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SIGN_IN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('mail', action.payload.mail);
      return {
        ...state,
      };
    case actions.SIGN_IN_FAIL:
      console.error('SIGN_IN_FAIL');
      return { ...state };
    case actions.USER_LOAD:
      return {
        ...state,
      };
    case actions.USER_LOAD_NO_DATA:
    case actions.USER_LOAD_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userLoaded: true,
        user: action.payload,
      };
    case actions.USER_LOAD_FAIL:
    case actions.SIGN_OUT:
      localStorage.removeItem('token');
      localStorage.removeItem('mail');
      // @TODO: borrar todo el store
      return {
        isAuthenticated: false,
        user: null,
        userLoaded: true,
      };
    default:
      return state;
  }
};
