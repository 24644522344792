/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader } from '@123-front/ui-kit';
import { signOut as signOutAction } from '../auth/store/actions';
import { carPropTypes } from '../cars/store/reducer-proptypes';
import { userPropTypes } from '../auth/store/reducer-proptypes';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { UserNotFound } from './components/user-not-found/UserNotFound';
import { ProductSelection } from './components/ProductSelection';

const InnerDashboard = ({ cars, loading, user, signOut }) => {
  const { t } = useSelfServiceTranslation();
  const onSignOut = () => signOut(t('routes.auth.login'));

  return (
    <div>
      {loading && <Loader />}
      {!loading &&
        (user && cars.length > 0 ? (
          <ProductSelection cars={cars} userName={user.name} />
        ) : (
          <UserNotFound signOut={onSignOut} />
        ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cars: state.cars.cars,
    loading: state.cars.loading,
    user: state.auth.user,
  };
};

InnerDashboard.propTypes = {
  cars: PropTypes.arrayOf(carPropTypes).isRequired,
  loading: PropTypes.bool,
  user: userPropTypes,
  signOut: PropTypes.func,
};

export const Dashboard = connect(mapStateToProps, { signOut: signOutAction })(InnerDashboard);
