/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { titleFont, subtitleFont, colors, Icon, icons, respondTo } from '@123-front/ui-kit';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';

const styles = {
  '.breadCrumb': {
    position: 'absolute',
    marginLeft: 72,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'baseline',
    [respondTo('sm')]: {
      marginLeft: 104,
    },
  },
  '.stepName': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    a: {
      span: {
        ...subtitleFont('sm'),
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: colors.gray.darkest,
      },
      '&:last-of-type span': {
        textTransform: 'inherit',
        ...titleFont('xs', 'serif'),
        color: colors.gray.darkest,
      },
    },
    '.arrowIcon': {
      cursor: 'pointer',
      margin: 8,
    },
  },
};

const BreadCrumb = () => {
  const { t, basePath } = useSelfServiceTranslation();

  const path = useLocation().pathname.split('/')[2];
  const params = useParams();
  const className = !path ? 'home' : 'child-page';

  return (
    <section css={styles}>
      <div className={`breadCrumb ${className}`}>
        <span className="stepName">
          <Link to={basePath}>
            <span>{t('breadcrumb.myInsurances')}</span>
          </Link>
          {path && <Icon icon={icons.chevronRight} className="arrowIcon" />}
          {path &&
            (params.plate ? (
              <Link to={t('routes.dashboard.circulation', { plate: params.plate.toUpperCase() })}>
                <span>{params.plate.toUpperCase()}</span>
              </Link>
            ) : (
              <Link to={t('routes.user.myAccount')}>
                <span>{t('breadcrumb.myAccount')}</span>
              </Link>
            ))}
        </span>
      </div>
    </section>
  );
};

export default BreadCrumb;
