import PropTypes from 'prop-types';

export const userPropTypes = PropTypes.exact({
  userId: PropTypes.string,
  personId: PropTypes.string,
  name: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  birthdate: PropTypes.string,
  legalId: PropTypes.exact({
    type: PropTypes.string,
    number: PropTypes.string,
  }),
  address: PropTypes.exact({
    country: PropTypes.string,
    city: PropTypes.string,
    district: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string,
    zipCode: PropTypes.string,
    floor: PropTypes.string,
    apartment: PropTypes.string,
  }),
});

export const authReducerPropTypes = PropTypes.exact({
  isAuthenticated: PropTypes.bool,
  user: userPropTypes,
  userLoaded: PropTypes.bool,
});
