/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Input, box, isValidEmail } from '@123-front/ui-kit';

const checkValidMail = (mail, setValidMail) => {
  setValidMail(isValidEmail(mail));
};

const styles = {
  ...box,
  padding: '24px 16px',
  '.user-field': { input: { width: '100%', boxSizing: 'border-box' } },
};

const RequestEmailForm = ({ onSubmit, value, children, defaultError, buttonText, texts }) => {
  const [mail, setMail] = useState(value);
  const [validMail, setValidMail] = useState(!!value);
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [submitActive, setSubmitActive] = useState(true);

  const handleChange = (e) => {
    setMail(e.target.value.toLowerCase());
  };

  const handleSubmit = async () => {
    setSubmitAttempt(true);
    if (isValidEmail(mail)) {
      setSubmitActive(false);
      await onSubmit(mail);
      setSubmitActive(true);
    }
  };
  const getErrors = () => {
    if (!mail && submitAttempt) {
      return texts.form.email.errorMandatory;
    }

    if (!validMail && submitAttempt) {
      return texts.form.email.errorFormat;
    }
    if (defaultError && !mail) {
      return defaultError;
    }
    return undefined;
  };
  return (
    <section css={styles}>
      <Input
        value={mail}
        className="user-field"
        error={getErrors()}
        onBlur={() => checkValidMail(mail, setValidMail)}
        onChange={handleChange}
        type="email"
        label={texts.form.email.label}
        info={texts.form.email.info}
      />
      {children}
      <Button block color="interactive" disabled={!submitActive} onClick={handleSubmit}>
        {buttonText}
      </Button>
    </section>
  );
};

RequestEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  defaultError: PropTypes.string,
  children: PropTypes.node,
  buttonText: PropTypes.string.isRequired,
  texts: PropTypes.shape({
    form: PropTypes.shape({
      email: PropTypes.shape({
        errorFormat: PropTypes.string,
        errorMandatory: PropTypes.string,
        label: PropTypes.string,
        info: PropTypes.string,
      }),
    }),
  }),
};

export default RequestEmailForm;
