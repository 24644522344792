import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { colorTheme, titleFont } from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { countryDomain } from '../../../shared/domainResolver';

const style = {
  ...titleFont,
  display: 'block',
  textAlign: 'center',
  margin: '24px 0 40px 0',
  '> h5': {
    fontSize: 12,
    fontWeight: '600',
    color: colorTheme.mute,
    textTransform: 'uppercase',
  },
  '> a': {
    fontSize: 16,
    color: colorTheme.interactive,
    textDecoration: 'none',
  },
};

export const PolicyNeedHelp = ({ className = '' }) => {
  const { t } = useSelfServiceTranslation();

  return (
    <div className={className} css={style}>
      <h5>{t('policy.help')}</h5>
      <a
        href={`https://www.123seguro.${countryDomain}/contacto`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('policy.contact')}
      </a>
    </div>
  );
};

PolicyNeedHelp.propTypes = {
  className: PropTypes.string,
};
