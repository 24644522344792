/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { box, colors, respondTo } from '@123-front/ui-kit';
import PropTypes from 'prop-types';

const styles = {
  ...box,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 156,
  height: 100,
  margin: 8,
  padding: 8,
  textDecoration: 'none',
  color: colors.gray.darkest,

  '&.active': {
    borderBottom: `4px solid ${colors.cyan.dark}`,
  },

  '&:hover': {
    textDecoration: 'none',
  },
  img: {
    width: 60,
    height: 60,
  },
  span: {
    fontSize: 13,
    color: colors.gray.darkest,
  },
  svg: {
    marginLeft: 4,
  },

  [respondTo('xs', 'md')]: {
    margin: 0,
    border: 0,
    borderRadius: 0,
    padding: '8px 16px',
    width: 'auto',
    height: 'auto',
    img: {
      width: 40,
      height: 40,
    },
    span: {
      display: 'none',
    },
  },
};

export const NavTab = ({ url, icon, title, className }) => {
  return (
    <Link to={url} css={styles} className={`navtab ${className}`}>
      <img src={icon} alt={title} />
      <span>{title}</span>
    </Link>
  );
};

NavTab.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NavTab.defaultProps = {
  className: '',
};
