/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { bodyFont, respondTo } from '@123-front/ui-kit';
import { InspectionStatus } from '../../../shared/inspection.enum';
import { InspectionToInspectCard } from '../../components/cards/InspectionToInspectCard';
import { InspectionReviewCard } from '../../components/cards/InspectionReviewCard';
import { InspectionApproved } from '../../components/cards/InspectionApproved';

const style = {
  '.inspection-card': {
    maxWidth: 328,
    margin: '20px auto 0',
    padding: '16px 10px 16px 16px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    border: '0 none',
    textAlign: 'left',
    img: {
      height: 35,
      width: 35,
      marginRight: 16,
    },
    [respondTo('md')]: {
      maxWidth: 360,
    },
  },
  '.inspection-card-title': {
    ...bodyFont('sm'),
    fontWeight: 'bold',
    margin: 0,
  },
  '.inspection-card-description': {
    ...bodyFont('sm'),
    marginLeft: 5,
    display: 'inline',
  },
};

export const InspectionCard = ({ status, manualLink }) => {
  const toInspect = status === InspectionStatus.TO_INSPECT;
  const toReview = status === InspectionStatus.SENT;
  const approved = status === InspectionStatus.APPROVED;
  const anyInspection = toInspect || toReview || approved;
  if (!anyInspection) {
    return null;
  }
  return (
    <div css={style} className="inspection-card">
      {toInspect && <InspectionToInspectCard manualLink={manualLink} />}
      {toReview && <InspectionReviewCard />}
      {approved && <InspectionApproved />}
    </div>
  );
};

InspectionCard.propTypes = {
  status: PropTypes.string,
  manualLink: PropTypes.string,
};
