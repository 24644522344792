/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { colors, respondTo } from '@123-front/ui-kit';
import config from '../../config';

const styles = {
  display: 'block',
  '.topRectangle, .backgroundImg': {
    position: 'absolute',
    width: '100%',
    left: 0,
    zIndex: -10,
  },
  '.topRectangle': {
    top: 0,
    height: 80,
    backgroundColor: colors.cyan.lightest,
  },
  '.backgroundImg': {
    top: 80,
  },
  [respondTo('md')]: {
    '.topRectangle': {
      height: 120,
    },
    '.backgroundImg': {
      top: 120,
    },
    '.backgroundImg:first-of-type': {
      display: 'none',
    },
  },
};

const BackgroundShapes = () => {
  return (
    <section css={styles}>
      <div className="topRectangle" />
      <img
        className="backgroundImg"
        src={`${config.brandingS3URL}/shapes/shape-curve-ag-blue-mobile.svg`}
        alt="mobile-img"
      />
      <img
        className="backgroundImg"
        src={`${config.brandingS3URL}/shapes/shape-curve-ag-blue-desktop.svg`}
        alt="desktop-img"
      />
    </section>
  );
};

export default BackgroundShapes;
