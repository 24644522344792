import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { Button, bodyFont, titleFont, colors } from '@123-front/ui-kit';
import { authContainer } from './styles';

/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  ...authContainer,
  margin: 'auto',
  overflow: 'hidden',
  '.top-text': { ...bodyFont('md'), marginTop: 24, marginBottom: 24, textAlign: 'center' },
  '.title': {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    margin: '0 0 12px 0',
    textAlign: 'center',
  },
  '.button-container': {
    textAlign: 'center',
  },
};

export const AlreadyRegistered = ({ texts, msg, onEnterTo123 }) => (
  <div css={styles}>
    <h2 className="title">{texts.title}</h2>
    <p className="top-text">{msg}</p>
    <div className="button-container">
      <Button variant="secondary" color="interactive" onClick={onEnterTo123}>
        {texts.form.button}
      </Button>
    </div>
  </div>
);

AlreadyRegistered.propTypes = {
  msg: PropTypes.string.isRequired,
  onEnterTo123: PropTypes.func.isRequired,
  texts: PropTypes.shape({
    title: PropTypes.string,
    form: PropTypes.shape({
      button: PropTypes.string,
    }),
  }),
};
