/** @jsxRuntime classic */
/** @jsx jsx */
import { respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const style = {
  width: 360,
  height: 204,
  padding: 24,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',

  [respondTo('xs', 'md')]: {
    width: 325,
    padding: 12,
  },
};

export const PaymentMethodBox = ({ className, children }) => {
  return (
    <div css={style} className={className}>
      {children}
    </div>
  );
};

PaymentMethodBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
