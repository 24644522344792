import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@123-front/ui-kit';

export const SignInAlertContent = ({ errorText, helpText, onClick, type }) => (
  <div>
    {errorText}
    <Button
      block
      variant="link"
      color={type === 'error' ? 'danger' : 'info'}
      className="help-needed"
      onClick={onClick}
    >
      {helpText}
    </Button>
  </div>
);

SignInAlertContent.propTypes = {
  errorText: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['error', 'info']),
};
