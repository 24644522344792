import { Loader } from '@123-front/ui-kit';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import Create from '../paymentMethods/Create';

const Status = {
  PENDING: 'PENDING',
  VALID: 'VALID',
  INVALID: 'INVALID',
};

const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const useEphemeralLink = () => {
  const [linkCheckStatus, setLinkCheckStatus] = useState(Status.PENDING);
  const linkId = useQueryParams().get('link');
  const [firstInstallmentAmount, setFirstInstallmentAmount] = useState();

  useEffect(() => {
    if (!linkId) {
      setLinkCheckStatus(Status.INVALID);
      return;
    }

    axios.get(`payments/ephemeral-links?link=${linkId}`).then(
      (res) => {
        if (res.data.firstInstallment) {
          setFirstInstallmentAmount(res.data.firstInstallment.amount);
        }
        setLinkCheckStatus(Status.VALID);
      },
      () => setLinkCheckStatus(Status.INVALID),
    );
  }, [linkId, setLinkCheckStatus]);

  return { linkCheckStatus, linkId, firstInstallmentAmount };
};

export const LinkHandler = ({ match }) => {
  const channel = match.params.channel || '';
  const { linkCheckStatus, linkId, firstInstallmentAmount } = useEphemeralLink();
  const { i18n } = useSelfServiceTranslation();
  const country = useMemo(() => i18n.language.toUpperCase(), [i18n]);

  if (linkCheckStatus === Status.PENDING) {
    return <Loader />;
  }

  if (linkCheckStatus === Status.VALID) {
    return (
      <Create
        linkId={linkId}
        country={country}
        channel={channel}
        firstInstallmentAmount={firstInstallmentAmount}
      />
    );
  }

  return <Redirect to={{ pathname: './invalid-link' }} />;
};

LinkHandler.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channel: PropTypes.string,
    }),
  }),
};

LinkHandler.defaultProps = {
  match: {
    params: {
      channel: '',
    },
  },
};
