import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import { RestorePassword } from '../shared/auth';
import { minHeight } from '../assets/styles';
import history from '../history';
import BackgroundShapes from '../shared/components/BackgroundShapes';
import { PublicHeader } from '../shared/header/PublicHeader';
import PublicFooter from '../shared/footer/PublicFooter';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { loadUser } from './store/actions';

/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  ...minHeight,
  paddingTop: 24,
  'h3.title': {
    marginTop: 0,
  },
};

const InnerAgRestorePassword = ({ match, menuClose }) => {
  const { token, userId } = match.params;
  const { t } = useSelfServiceTranslation();
  const texts = {
    form: {
      title: t('restorePassword.form.title'),
      topText: t('restorePassword.form.topText'),
      tokenError: t('restorePassword.form.tokenError'),
      goToLogin: t('restorePassword.form.goToLogin'),
      button: t('restorePassword.form.button'),
      password: {
        errorMandatory: t('userRegister.form.password.errorMandatory'),
        errorFormat: t('userRegister.form.password.errorFormat'),
        label: t('restorePassword.form.title'),
        info: t('userRegister.form.password.info'),
      },
      errors: {
        unexpected: t('userRegister.form.errors.unexpected'),
      },
    },
  };
  const goToLogin = () => history.push(t('routes.auth.login'));

  return (
    <div>
      <PublicHeader menuClose={menuClose} />
      <div css={styles}>
        <BackgroundShapes />
        <RestorePassword
          texts={texts}
          onSubmit={goToLogin}
          onEnterTo123={goToLogin}
          token={token}
          userId={userId}
          config={{ whatsapp: t('attention.whatsapp') }}
        />
      </div>
      <PublicFooter />
    </div>
  );
};

InnerAgRestorePassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
  }).isRequired,
  menuClose: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  menuClose: state.shared.menuClose,
});

export const AgRestorePassword = connect(mapStateToProps, { loadUser })(InnerAgRestorePassword);
