import React from 'react';
import { connect } from 'react-redux';
import { ChangePassword } from '../../shared/auth';
import history from '../../history';
import config from '../../config';
import { userPropTypes } from '../../auth/store/reducer-proptypes';
import BackgroundShapes from '../../shared/components/BackgroundShapes';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';

const ChangeAccountPassword = ({ user }) => {
  const { t, basePath } = useSelfServiceTranslation();
  const texts = {
    form: {
      title: t('restorePassword.form.title'),
      topText: t('restorePassword.form.topText'),
      tokenError: t('restorePassword.form.tokenError'),
      button: t('restorePassword.form.button'),
      password: {
        errorMandatory: t('userRegister.form.password.errorMandatory'),
        errorFormat: t('userRegister.form.password.errorFormat'),
        label: t('restorePassword.form.title'),
        info: t('userRegister.form.password.info'),
      },
      errors: {
        unexpected: t('userRegister.form.errors.unexpected'),
      },
    },
  };
  if (user) {
    texts.form.topText = `${t('changePassword.topText', {
      user: user.name,
      email: user.email,
    })}`;
  }

  const afterSubmit = (response) => {
    if (response.data.n) {
      history.push(basePath);
    }
  };

  return (
    <section>
      <BackgroundShapes />
      <ChangePassword
        texts={texts}
        appId={config.applicationId}
        userId={user.userId}
        token={localStorage.getItem('token')}
        onSubmit={afterSubmit}
      />
    </section>
  );
};

ChangeAccountPassword.propTypes = {
  user: userPropTypes.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ChangeAccountPassword);
