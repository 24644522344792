/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { titleFont, bodyFont, colors } from '@123-front/ui-kit';
import config from '../../config';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';

const styles = {
  width: 360,
  margin: 'auto',

  '.notFoundImg, .dashboard': {
    display: 'flex',
    justifyContent: 'center',
  },

  '.notFoundImg': {
    marginTop: '40px',
    marginBottom: '24px',
  },
  h1: {
    ...titleFont('md', 'serif'),
    textAlign: 'center',
    color: colors.gray.darkest,
  },
  p: {
    ...bodyFont('md', 'serif'),
    textAlign: 'center',
    color: colors.gray.darkest,
  },
  '.dashboard': {
    padding: 14,
    border: 'solid 1px',
    borderRadius: 5,
    marginTop: 40,
    fontWeight: 'bold',
  },
  '.notFoundContact': {
    paddingTop: 12,
    borderTop: `solid 1px ${colors.gray.lighten}`,
    p: {
      ...bodyFont('sm', 'serif'),
      color: colors.gray.dark,
      margin: 5,
    },
    a: {
      color: 'inherit',
    },
  },
};

const NotFound = () => {
  const { t, basePath } = useSelfServiceTranslation();

  const email = t('attention.customerSupport');
  const phone = t('attention.phone');

  return (
    <div css={styles}>
      <div className="notFoundImg">
        <img src={`${config.brandingS3URL}/errors/img-404.svg`} alt="notfound" title="notfound" />
      </div>
      <h1>{t('pageNotFound.title')}</h1>
      <p>{t('pageNotFound.message')}</p>
      <div className="notFoundContact">
        <p>{t('pageNotFound.contact.title')}</p>
        <p>
          <a href={`tel:${phone}`}>{t('pageNotFound.contact.phone', { phone })}</a>
        </p>
        <p>
          <a href={`mailto: ${email}`}>{t('pageNotFound.contact.email', { email })}</a>
        </p>
      </div>
      <Link className="dashboard" to={basePath}>
        {t('pageNotFound.goBack')}
      </Link>
    </div>
  );
};

export default NotFound;
