import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Container, Header, Row } from '@123-front/ui-kit';
import { parse } from 'query-string';
import { lazy, Suspense } from 'react';
import PolicyCard from '../dashboard/policy/components/PolicyCard';
import config from '../config';
import BodyMenu from '../shared/header/components/BodyMenu';
import HeaderMenu from '../shared/header/components/HeaderMenu';
import { minHeight } from '../assets/styles';
import history from '../history';
import PublicFooter from '../shared/footer/PublicFooter';
import { domainLocation } from '../shared/domainResolver';
import { PolicyNeedHelp } from '../dashboard/policy/components/PolicyNeedHelp';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';

//TODO: hay que desacoplar de aca el "¿No encontraste lo que buscabas?
const PolicyFooter = lazy(() =>
  import(`../dashboard/policy/components/${domainLocation}/PolicyFooter`).catch(() => null),
);

const style = {
  ...minHeight,
  padding: 16,
  '.policy-box': {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

export const PublicPolicy = ({ location }) => {
  const { t, basePath } = useSelfServiceTranslation();

  let url;
  if (!location.search) {
    history.push(basePath);
  } else {
    // TODO: hotfix, volver para atras en un futuro
    let urlQuery = parse(location.search).e.replace(/%2F/g, '/');
    urlQuery = urlQuery.includes('docs') ? urlQuery : urlQuery.replace('s3', 's3/docs');
    url = `${config.services.s3}/${urlQuery}`;
  }
  return (
    <div>
      <Header menuContent={<BodyMenu />} menuEnabled menuTitle={<HeaderMenu />} />
      <Container>
        <div css={style}>
          <div>
            <Row>
              <PolicyCard
                className="policy-box"
                description={t('policy.description')}
                title={t('policy.title')}
                fileUrl={url}
              />
            </Row>
            <PolicyNeedHelp />
            <Suspense fallback={<div />}>
              <PolicyFooter />
            </Suspense>
          </div>
        </div>
      </Container>
      <PublicFooter />
    </div>
  );
};

PublicPolicy.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};
