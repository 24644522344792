/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { respondTo } from '@123-front/ui-kit';

import config from '../../config';

const styles = {
  display: 'none',
  [respondTo('md')]: {
    display: 'block',
    position: 'fixed',
    top: 150,
    width: '100%',
    '.dots': {
      position: 'absolute',
      transform: 'rotate(90deg)',
      left: -60,
    },
  },
};

export function DotsBackground() {
  return (
    <div css={styles}>
      <img
        className="dots"
        src={`${config.brandingS3URL}/miscellaneous/pattern-dots-home-nosotros.svg`}
        alt="pattern"
      />
    </div>
  );
}
