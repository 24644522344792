import { useTranslation } from 'react-i18next';

/**
 * @note needs to be erased for code and use directly useTranslation
 * @todo https://123seguro.atlassian.net/browse/BRA-3493
 */
export const useSelfServiceTranslation = () => {
  const translation = useTranslation();
  return {
    ...translation,
    t: (key, params) => translation.t(`${key}`, params),
    exists: (key) => translation.i18n.exists(`${key}`),
    basePath: translation.t('routes.basePath'),
  };
};
