import { getExpirationMonth, getExpirationYear } from './expiration-date';
import { getKey } from './fetch-cybersource-key';

let flex = null;
let brandName = '';

export const initCyberSource = async ({ styles, elements, onSubmit }) => {
  const keyResult = await getKey();

  if (!keyResult.ok) {
    return {
      ok: false,
      errorMessage: keyResult.errorMessage,
    };
  }

  if (!flex) {
    const loadCyberSourceResult = await loadCyberSource(keyResult.key.scriptUrl);

    if (!loadCyberSourceResult.ok) {
      return {
        ok: false,
        errorMessage: loadCyberSourceResult.errorMessage,
      };
    }

    const Flex = loadCyberSourceResult.Flex;
    flex = new Flex(keyResult.key.id);
  }

  const microform = flex.microform({ styles });
  const cardNumber = microform.createField('number', {
    placeholder: elements.cardNumberPlaceholder,
  });
  const securityCode = microform.createField('securityCode', {
    placeholder: elements.securityCodePlaceholder,
  });

  cardNumber.on('change', (data) => {
    brandName = data.valid ? data.card[0].brandedName : '';
  });

  cardNumber.load(`#${elements.cardNumber}`);
  securityCode.load(`#${elements.securityCode}`);

  const payButton = document.querySelector(`#${elements.payButton}`);

  payButton.addEventListener('click', () => {
    const cardHolder = document.querySelector(`#${elements.cardHolder}`).value;
    const dueDate = document.querySelector(`#${elements.dueDate}`).value;

    const { error, expirationMonth, expirationYear } = parseDueDate(dueDate);

    if (error) {
      onSubmit(error, null);
      return;
    }

    const options = { expirationMonth, expirationYear };

    microform.createToken(options, function (err, token) {
      if (err) {
        onSubmit(parseTokenizationError(err), null);
        return;
      }
      onSubmit(null, { contextKey: keyResult.key.id, token, brandName, cardHolder, dueDate });
    });
  });

  return { ok: true };
};

const loadCyberSource = async (scriptUrl) => {
  return new Promise((resolve, reject) => {
    if (window.Flex) {
      console.log('CyberSource Flex module already loaded');
      resolve({ ok: true, Flex: window.Flex });
      return;
    }

    try {
      const script = createScript(scriptUrl);

      script.addEventListener('load', () => {
        if (window.Flex) {
          resolve({ ok: true, Flex: window.Flex });
        } else {
          console.error('CyberSource Flex script not available');
          reject({ ok: false, errorMessage: 'paymentMethods.createPaymentMethod.errorMessage' });
        }
      });

      script.addEventListener('error', () => {
        console.error('Failed to load CyberSource Flex script');
        reject({ ok: false, errorMessage: 'paymentMethods.createPaymentMethod.errorMessage' });
      });
    } catch (error) {
      console.error('CyberSource Flex module error: ', JSON.stringify(error));
      reject({ ok: false, errorMessage: 'paymentMethods.createPaymentMethod.errorMessage' });
      return;
    }
  });
};

const createScript = (scriptUrl) => {
  const url = scriptUrl;
  const script = document.createElement('script');

  script.src = url;

  const element = document.head || document.body;

  element.appendChild(script);

  return script;
};

const parseDueDate = (dueDate) => {
  const expirationMonth = getExpirationMonth(dueDate);

  if (!expirationMonth) {
    return {
      error: {
        message: 'paymentMethods.createPaymentMethod.errorFieldMessage',
        fields: { dueDate: 'paymentMethods.createPaymentMethodValidation.dueDateInvalid' },
      },
    };
  }

  const expirationYear = getExpirationYear(dueDate);

  if (!expirationYear) {
    return {
      error: {
        message: 'paymentMethods.createPaymentMethod.errorFieldMessage',
        fields: { dueDate: 'paymentMethods.createPaymentMethodValidation.dueDateInvalid' },
      },
    };
  }

  return {
    expirationMonth: expirationMonth.toString().padStart(2, '0'),
    expirationYear: (2000 + expirationYear).toString(),
  };
};

const parseTokenizationError = (error) => {
  console.error(JSON.stringify(error));

  const fieldsMapping = {
    expirationYear: {
      dueDate: 'paymentMethods.createPaymentMethodValidation.dueDateInvalid',
    },
    number: {
      cardNumber: 'paymentMethods.createPaymentMethodValidation.cardNumberInvalid',
    },
    securityCode: {
      securityCode: 'paymentMethods.createPaymentMethodValidation.securityCodeInvalid',
    },
  };

  switch (error.reason) {
    case 'CREATE_TOKEN_VALIDATION_FIELDS':
    case 'CREATE_TOKEN_VALIDATION_SERVERSIDE':
      const fieldError = fieldsMapping[error.details[0].location] || {};
      return {
        message: 'paymentMethods.createPaymentMethod.errorFieldMessage',
        fields: fieldError,
      };
    default:
      return { message: 'paymentMethods.createPaymentMethod.errorMessage' };
  }
};
