import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import { SetPassword } from '../shared/auth';
import { minHeight } from '../assets/styles';
import config from '../config';
import history from '../history';
import BackgroundShapes from '../shared/components/BackgroundShapes';
import { PublicHeader } from '../shared/header/PublicHeader';
import PublicFooter from '../shared/footer/PublicFooter';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { loadUser } from './store/actions';

/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  ...minHeight,
  paddingTop: 24,
  'h2.title': {
    marginTop: 0,
  },
};

const InnerUserRegister = ({ match, menuClose }) => {
  const { t } = useSelfServiceTranslation();

  const goToLogin = () => history.push(t('routes.auth.login'));
  // TODO: la unica forma que encontre de cambiar la url sin romper todo
  const pushInvalidToken = () =>
    window.history.pushState(
      t('register.inactiveToken'),
      t('SelfService'),
      t('routes.auth.registerInactiveAccount'),
    );

  const componentConfig = {
    token: match.params.token,
    // @Deprecated remove it in future releases
    mail: match.params.mail.includes('@') ? match.params.mail : window.atob(match.params.mail),
    userId: match.params.id,
    termsLink: config.termsLink,
    whatsapp: t('attention.whatsapp'),
  };

  const texts = {
    terms: t('userRegister.terms'),
    title: t('userRegister.title'),
    topText: t('userRegister.topText'),
    form: {
      button: t('userRegister.form.button'),
      alreadyRegisteredButton: t('userRegister.form.alreadyRegisteredButton'),
      goToLogin: t('userRegister.form.goToLogin'),
      password: {
        label: t('userRegister.form.password.label'),
        info: t('userRegister.form.password.info'),
        errorMandatory: t('userRegister.form.password.errorMandatory'),
        errorFormat: t('userRegister.form.password.errorFormat'),
      },
      email: {
        label: t('userRegister.form.email.label'),
      },
      checkbox: {
        label: t('userRegister.form.checkbox.label'),
        errorMandatory: t('userRegister.form.checkbox.errorMandatory'),
      },
      errors: {
        mailAlreadyExists: t('userRegister.form.errors.mailAlreadyExists'),
        clientExists: t('userRegister.form.errors.clientExists'),
        unexpected: t('userRegister.form.errors.unexpected'),
        expiredLink: t('userRegister.form.errors.expiredLink'),
      },
    },
    registerTitle: t('userRegister.registerTitle'),
  };

  return (
    <div>
      <BackgroundShapes />
      <PublicHeader menuClose={menuClose} />
      <div css={styles}>
        <SetPassword
          config={componentConfig}
          onSubmit={goToLogin}
          texts={texts}
          onAlreadyRegistered={pushInvalidToken}
        />
      </div>
      <PublicFooter />
    </div>
  );
};

InnerUserRegister.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mail: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  }).isRequired,
  menuClose: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  menuClose: state.shared.menuClose,
});

export const UserRegister = connect(mapStateToProps, { loadUser })(InnerUserRegister);
