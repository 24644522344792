/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { respondTo } from '@123-front/ui-kit';

import config from '../../config';

const styles = {
  position: 'relative',
  zIndex: -1,
  [respondTo('xs', 'md')]: {
    paddingTop: 20,
  },
  img: {
    width: '100%',
    maxWidth: 550,
    height: 'auto',
    [respondTo('xs', 'md')]: {
      width: 455,
      height: 360,
      position: 'relative',
      right: -65,
    },
  },
};

export function Hero() {
  return (
    <div css={styles}>
      <img src={`${config.brandingS3URL}/figures/fig_ag-appdownload_hero.png`} alt="pattern" />
    </div>
  );
}
