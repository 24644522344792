import PropTypes from 'prop-types';
import { bodyFont, colors } from '@123-front/ui-kit';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

const styles = {
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: '4px 0',
    li: {
      padding: '6px 0',
      '&:first-of-type': {
        a: { textTransform: 'uppercase', fontWeight: '600', color: colors.gray.darkest },
      },
      a: { ...bodyFont('sm'), textDecoration: 'none' },
    },
  },
};

export const FooterMenuSection = ({ items, className = '' }) => {
  return (
    <div className={className} css={styles}>
      <ul>
        {items.map((item, i) => {
          return (
            <li key={item.label}>
              <a href={item.url} id={i.toString()}>
                {item.label}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FooterMenuSection.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};
