import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { colors } from '@123-front/ui-kit';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { closeMenu as closeMenuAction } from '../store/actions';

const styles = {
  a: {
    '&:hover': {
      color: colors.cyan.dark,
    },
  },
};

const Item = ({ title, externalPath, path, className, closeMenu }) => {
  return (
    <div css={styles} key={title}>
      {externalPath && (
        <a href={externalPath} target="_blank" rel="noopener noreferrer" className={className}>
          <span>{title}</span>
        </a>
      )}
      {path && (
        <Link onClick={closeMenu} to={path} className={className}>
          <span>{title}</span>
        </Link>
      )}
    </div>
  );
};

Item.defaultProps = {
  path: undefined,
  externalPath: undefined,
  className: undefined,
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  externalPath: PropTypes.string,
  className: PropTypes.string,
  closeMenu: PropTypes.func.isRequired,
};

export default connect(null, { closeMenu: closeMenuAction })(Item);
