/** @jsxRuntime classic */
/** @jsx jsx */
import { Alert, Column, Container, Row, titleFont } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import { Header } from '../ephemeral-link/Header';
import { getChannelData } from '../ephemeral-link/channel-branding';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import FormCard from './FormCard';
import FormType from './FormType';
import documentTypes from './document-types.json';
import { validatePaymentForm } from './payment-form-validation-schema';
import { BankAccountDetails } from './payment-method-box/BankAccountDetails';
import CardDetails from './payment-method-box/CardDetails';
import { CyberSourceBrick } from './payment-method-box/CyberSourceBrick';
import { MercadoPagoBrick } from './payment-method-box/MercadoPagoBrick';
import { PaymentTypes } from './payment-method-types.enum';
import { validatePaymentType } from './type-validation-schema';

const styles = {
  padding: '0',
  '.title': {
    ...titleFont('md', 'serif'),
    textAlign: 'center',
    color: '#333333',
    margin: '34px 0 40px',
  },
  '.form-container': {
    marginBottom: 35,
  },
  '.title, .form-container, .card-container': {
    display: 'flex',
    webkitBoxPack: 'center',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0,
    width: '100%',
  },
  '.card-view': {
    marginBottom: '16px',
    ' > div': {
      flex: 'inherit',
      margin: 0,
    },
  },
};

const formSteps = {
  TYPE: 0,
  CARD: 1,
};

const Create = ({ linkId, country, channel, firstInstallmentAmount }) => {
  const { t, ready } = useSelfServiceTranslation();
  const valuesSchema = {
    country,
    linkId,
    type: null,
    brand: {},
    issuer: {},
    cardNumber: '',
    dueDate: '',
    cardHolder: '',
    legalIdType: documentTypes[country][0],
    holderLegalId: '',
    bank: '',
    accountType: '',
    accountNumber: '',
  };

  const [formStep, setFormStep] = useState(formSteps.TYPE);
  const [values, setValues] = useState(valuesSchema);
  const [typeErrors, setTypeErrors] = useState({});
  const [cardErrors, setCardErrors] = useState({});
  const [paymentMethodTypes, setPaymentMethodTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams();
  const { includeTermsConditions } = getChannelData(params.channel);

  useEffect(() => {
    axios.get(`payments/payment-method-types?linkId=${linkId}`).then((res) => {
      setPaymentMethodTypes(res.data);
      setValues((prevValues) => ({ ...prevValues, type: res.data[0] }));
    });
  }, [linkId]);

  const messages = t('paymentMethods.createPaymentMethodValidation', {
    returnObjects: true,
    legalIdType: values.legalIdType.name,
  });

  const nextHandler = () => {
    const validationErrors = validatePaymentType(values, messages);
    setTypeErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setFormStep(formSteps.CARD);
    }
  };

  const prevHandler = () => {
    setFormStep(formSteps.TYPE);
  };

  const onSaveHandler = () => {
    const validationErrors = validatePaymentForm(values, messages);
    if (includeTermsConditions && !values.termsConditions) {
      validationErrors.termsConditions = 'Es necesario que aceptes los términos y condiciones';
    }
    setCardErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      createPaymentMethod({ ...values, channel })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }

          window.location = res.url;
        })
        .catch(() => setErrorMessage(t('paymentMethods.createPaymentMethod.errorMessage')));
    }
  };

  if (!ready || !values.type) {
    return null;
  }

  if (values.type.type === PaymentTypes.MERCADO_PAGO_PRU_MX) {
    return (
      <div css={styles}>
        <Header channel={channel} />
        <Container>
          <Column>
            <Row className="title">
              <div>{t('paymentMethods.pageTitle.CREDIT_CARD')}</div>
            </Row>
            <Row className="form-container">
              <MercadoPagoBrick
                linkId={linkId}
                country={country}
                channel={channel}
                amount={firstInstallmentAmount}
              />
            </Row>
          </Column>
        </Container>
      </div>
    );
  }

  if (values.type.type === PaymentTypes.CYBERSOURCE_PRU_BR) {
    return (
      <div css={styles}>
        <Header channel={channel} />
        <Container>
          <Row className="title">
            <div>{t('paymentMethods.pageTitle.CYBERSOURCE_PRU_BR')}</div>
          </Row>
          <Row className="form-container">
            <CyberSourceBrick
              linkId={linkId}
              country={country}
              channel={channel}
              amount={firstInstallmentAmount}
            />
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div css={styles}>
      <Header channel={channel} />
      <Container>
        {errorMessage ? <Alert type="error" text={errorMessage} dismissible /> : null}
        <Column>
          <Row className="title">
            <div>{t(`paymentMethods.pageTitle.${values.type?.type}`)}</div>
          </Row>
        </Column>
        <div className="form-container">
          <Column>
            <Row className="card-container card-view">
              {values.type?.type === PaymentTypes.CREDIT_CARD ? (
                <CardDetails
                  type={values.type?.type}
                  holderName={values.cardHolder}
                  number={values.cardNumber || '**** **** **** ****'}
                  brand={values.brand?.logo}
                  issuer={values.issuer?.logo}
                  expirationMonth={getExpirationMonth(values.dueDate)}
                  expirationYear={getExpirationYear(values.dueDate)}
                  disabled={!values.brand.name || !values.issuer.name}
                />
              ) : (
                <BankAccountDetails
                  key={values.type?.type}
                  issuer={values.bank?.logo}
                  number={values.accountNumber || '*******************'}
                  holderName={values.cardHolder}
                  holderIdNumber={values.holderLegalId || ''}
                  holderIdType={values.legalIdType.id || ''}
                />
              )}
            </Row>
            <Row className="card-container">
              {formStep === formSteps.TYPE && values.type && (
                <FormType
                  linkId={linkId}
                  paymentMethodTypes={paymentMethodTypes}
                  values={values}
                  setValues={setValues}
                  nextHandler={nextHandler}
                  errors={typeErrors}
                />
              )}
            </Row>
            <Row className="card-container">
              {formStep === formSteps.CARD && (
                <FormCard
                  values={values}
                  setValues={setValues}
                  prevHandler={prevHandler}
                  onSave={onSaveHandler}
                  errors={cardErrors}
                />
              )}
            </Row>
          </Column>
        </div>
      </Container>
    </div>
  );
};

Create.propTypes = {
  linkId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  channel: PropTypes.string,
  firstInstallmentAmount: PropTypes.number,
};

Create.defaultProps = {
  channel: '',
};

export default Create;

const getExpirationMonth = (dueDate) => {
  if (!dueDate || dueDate.includes('_')) return null;
  if (dueDate[0] === '0') return Number(dueDate[1]);
  return Number(dueDate.substring(0, 2));
};

const getExpirationYear = (dueDate) => {
  if (!dueDate || dueDate.includes('_')) return null;
  return Number(dueDate.substring(3, 5));
};

const createPaymentMethod = async (values) => {
  const paymentNumber =
    values.type.type === PaymentTypes.BANK_ACCOUNT ? values.accountNumber : values.cardNumber;
  const payload = {
    linkId: values.linkId,
    country: values.country,
    channel: values.channel,
    type: values.type.type,
    brandId: values.brand?.id,
    bankId: values.bank?.id,
    issuerId: values.issuer?.id,
    accountType: values.accountType?.type,
    number: paymentNumber.replace(/[^\d]+/g, ''),
    expiryMonth: getExpirationMonth(values.dueDate),
    expiryYear: getExpirationYear(values.dueDate),
    holderName: values.cardHolder,
    legalIdType: values.legalIdType.id,
    legalIdNumber: values.holderLegalId,
  };

  return fetch(`${config.basePath}/api/payments/payment-methods`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    redirect: 'follow',
  });
};
