import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Header, respondTo } from '@123-front/ui-kit';
import config from '../../config';
import HeaderMenu from './components/HeaderMenu';
import BodyMenu from './components/BodyMenu';

const styles = {
  'header:first-of-type': { [respondTo('xs', 'sm')]: { position: 'absolute' } },
  '.home-link': {
    margin: '0 auto',
    padding: 4,
    '.logo-123': {
      height: 36,
    },
  },
};

export const PublicHeader = ({ className = '', menuClose, menuDisabled = false }) => {
  return (
    <div css={styles} className={className}>
      <Header
        closedFromOutside={menuClose}
        menuContent={<BodyMenu />}
        menuEnabled={!menuDisabled}
        menuTitle={<HeaderMenu />}
      >
        <a className="home-link" href="/">
          <img
            className="logo-123"
            src={`${config.brandingS3URL}/logotypes/123/color/logo_123.svg`}
            alt="123Seguro"
            title="123Seguro"
          />
        </a>
      </Header>
    </div>
  );
};

PublicHeader.propTypes = {
  menuClose: PropTypes.bool.isRequired,
  menuDisabled: PropTypes.bool,
  className: PropTypes.string,
};
