/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  getPhoneLink,
  baseLink,
  box,
  colors,
  bodyFont,
  titleFont,
  respondTo,
  Button,
  Divider,
  Icon,
  icons,
} from '@123-front/ui-kit';

const styles = {
  position: 'relative',
  '.arrowBackIcon': {
    marginLeft: 0,
    marginTop: 0,
  },
  [respondTo('md')]: {
    '.arrowBackIcon': {
      marginLeft: -24,
      marginTop: 8,
    },
  },
  '.header': {
    position: 'relative',
  },
  '.back': {
    background: 'none',
    border: 0,
    position: 'absolute',
    left: 0,
  },
  '.title': {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    margin: '24px 0 0 0',
    textAlign: 'center',
  },
  '.top-text': { ...bodyFont('md') },
  '.box': {
    ...box,
    padding: 16,
    a: {
      ...bodyFont('md'),
      ...baseLink,
      '.icon': { color: colors.gray.dark, marginRight: 8 },
    },
  },
};

const ForgotUser = ({ config, texts, setForgotUser }) => {
  return (
    <div css={styles}>
      <div className="header">
        <Button className="back" type="button" variant="link" onClick={() => setForgotUser(false)}>
          <Icon icon={icons.back} size="24" color={colors.cyan.dark} className="arrowBackIcon" />
        </Button>
        <h3 className="title">{texts.title}</h3>
      </div>
      <p className="top-text">{texts.topText}</p>
      <div className="box">
        <a href={getPhoneLink(config.whatsapp)} target="_blank" rel="noopener noreferrer">
          <Icon className="icon" icon={icons.whatsapp} size={20} color={colors.gray.dark} />
          {config.whatsapp}
        </a>
        <Divider />
        <a href={`mailto:${config.customerSupport}`}>
          <Icon className="icon" icon={icons.email} size={20} color={colors.gray.dark} />
          {config.customerSupport}
        </a>
      </div>
    </div>
  );
};

ForgotUser.propTypes = {
  config: PropTypes.shape({
    whatsapp: PropTypes.string.isRequired,
    customerSupport: PropTypes.string.isRequired,
  }).isRequired,
  texts: PropTypes.shape({
    title: PropTypes.string,
    topText: PropTypes.string,
  }),
  setForgotUser: PropTypes.func.isRequired,
};

export default ForgotUser;
