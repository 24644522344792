import { connect } from 'react-redux';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import {
  Divider,
  bodyFont,
  titleFont,
  box,
  colors,
  icons,
  Icon,
  getPhoneLink,
  getWhatsappLink,
  Container,
  Row,
  Column,
  rowAlign,
  respondTo,
} from '@123-front/ui-kit';
import { carPropTypes } from '../../cars/store/reducer-proptypes';
import { findCarByPlate } from '../../cars/car.utils';
import config from '../../config';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';

/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  padding: '24px 16px 40px 16px',
  [respondTo('lg')]: {
    padding: '0 24px 80px 24px',
  },
  '.row-main': {
    ...rowAlign('center'),
  },
  h1: {
    position: 'relative',
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
    padding: '0 40px',
  },
  '.link-back': {
    position: 'absolute',
    left: 0,
    top: '50%',
    marginTop: -12,
    fontSize: 0,
    lineHeight: 24,
  },
  '.box-contact': {
    ...box,
    padding: 16,
    ...bodyFont('sm'),
    p: { margin: 0 },
    a: {
      transition: 'color 0.2s',
      '&:hover': { textDecoration: 'none' },
    },
  },
  '.row-contact': {
    display: 'flex',
    alignItems: 'center',
    figure: { margin: 0 },
    img: { width: 80 },
    p: { textAlign: 'left', paddingLeft: 16, flex: 2 },
    span: { display: 'block' },
    a: {
      ...bodyFont('lg'),
      margin: '4px 0',
      color: colors.cyan.dark,
      '&:hover': { color: colors.cyan.medium },
    },
  },
};

const ClaimClashStatus = ({ car }) => {
  const { t } = useSelfServiceTranslation();

  return (
    <div>
      {car ? (
        <div>
          <SelfmanagmentNavbar plate={car.plate} activeTab={NavtabId.SINISTERS} />
          <div css={styles}>
            <Container>
              <Row internal={false} className="row-main">
                <Column mobile={12} desktop={4}>
                  <h1>
                    <Link
                      className="link-back"
                      to={t('routes.dashboard.claimCrash', { plate: car.plate.toLowerCase() })}
                    >
                      <Icon icon={icons.arrowBack} size="24" color={colors.cyan.dark} />
                    </Link>
                    <span>{t('claimCrash.status.title')}</span>
                  </h1>
                  <div className="box-contact">
                    <div className="row-contact">
                      <figure>
                        <img
                          src={`${config.brandingS3URL}/images/img_whatsapp.svg`}
                          alt="whatsapp"
                        />
                      </figure>
                      <p>
                        <span>{t('claimCrash.status.title')}</span>
                        <a
                          href={getWhatsappLink(t('attention.whatsapp'))}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('attention.whatsapp')}
                        </a>
                        <span>{t('claimCrash.status.schedule')}</span>
                      </p>
                    </div>
                    <Divider />
                    <p>
                      <span>{t('claimCrash.status.callTo')}</span>{' '}
                      <a href={getPhoneLink(t('attention.phone'))}>{t('attention.phone')}</a>{' '}
                    </p>
                  </div>
                </Column>
              </Row>
            </Container>
          </div>
        </div>
      ) : null}
    </div>
  );
};

ClaimClashStatus.propTypes = {
  car: carPropTypes.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  return {
    car: findCarByPlate(state.cars.cars, ownProps.match.params.plate),
  };
};

export default connect(mapStateToProps)(ClaimClashStatus);
