import React from 'react';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import { domainLocation } from './shared/domainResolver';
import * as config from './config';

const GoogleTagmanager = () => {
  React.useEffect(() => {
    if (domainLocation in config.gtmIds) {
      const gtmId = config.gtmIds[domainLocation];
      TagManager.initialize({ gtmId });
    }

    if (config.hotjar) {
      const { hjid, hjsv } = config.hotjar;
      hotjar.initialize(hjid, hjsv);
    }
  }, []);

  return null;
};

export default GoogleTagmanager;
