/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Divider, colors, respondTo } from '@123-front/ui-kit';

import { Suspense, lazy } from 'react';
import { domainLocation } from '../domainResolver';
import FooterTop from './components/FooterTop';
import FooterMenu from './components/FooterMenu';

const styles = {
  padding: 16,
  [respondTo('lg')]: {
    paddingLeft: 100,
    paddingRight: 100,
  },
  backgroundColor: colors.gray.ultralight,
};

const loadFooterLegal = (location) => {
  const FooterLegal = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/FooterLegal`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <FooterLegal />;
};

const loadFooterContact = (location) => {
  const FooterContact = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/FooterContact`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <FooterContact />;
};

const Footer = () => {
  return (
    <footer css={styles}>
      <FooterTop />
      <Divider />
      <FooterMenu />
      <Suspense fallback={<div />}>{loadFooterContact(domainLocation)}</Suspense>
      <Suspense fallback={<div />}>{loadFooterLegal(domainLocation)}</Suspense>
    </footer>
  );
};

export default Footer;
