/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { colors, icons, Icon } from '@123-front/ui-kit';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { cardStyles } from './cardStyles';

const styles = {
  ...cardStyles,
  '.card-logo': {
    ...cardStyles['.card-logo'],
    marginLeft: 20,
    marginRight: 20,
    width: 60,
  },
};

export const MyAccountCard = () => {
  const { t } = useSelfServiceTranslation();

  return (
    <div css={styles}>
      <Link className="link" to={t('routes.user.myAccount')}>
        <div className="container">
          <img
            className="card-logo"
            alt="Mi cuenta"
            src={`${config.brandingS3URL}/images/img_configuration.svg`}
          />
          <div className="card-details">
            <h4 className="card-title">{t('productSelection.accountCard.title')}</h4>
          </div>
        </div>
        <Icon icon={icons.chevronRight} color={colors.cyan.dark} size={28} className="icon" />
      </Link>
    </div>
  );
};
