import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { bodyFont, getWhatsappLink, Icon, icons } from '@123-front/ui-kit';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { AppDownloadButtons } from '../../../shared/components/AppDownloadButtons';

const styles = {
  padding: 16,
  paddingBottom: 32,

  p: {
    ...bodyFont('md', 'sans'),
    marginBottom: 0,
  },

  '.button-panel': {
    marginTop: 24,
    marginBottom: 24,
    height: 30,
  },

  '.contact-text': {
    marginBottom: 24,
  },

  '.contact': {
    '.contact-row': {
      marginBottom: 16,
      svg: {
        marginRight: 8,
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
};

export const UserNotFoundChannels = ({ className = '' }) => {
  const { t } = useSelfServiceTranslation();

  return (
    <div css={styles} className={className}>
      <p>{t('userNotFound.channels.text.header')}</p>
      <AppDownloadButtons className="button-panel" />

      <p className="contact-text">{t('userNotFound.channels.text.contact')}</p>

      <div className="contact">
        <div className="contact-row">
          <Icon icon={icons.whatsapp} size="md" />
          <a
            href={getWhatsappLink(t('attention.whatsapp'))}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('attention.whatsapp')}
          </a>
        </div>
        <div className="contact-row">
          <Icon icon={icons.email} size="md" />
          <a href={`mailto:${t('attention.customerSupport')}`}>{t('attention.customerSupport')}</a>
        </div>
        <div className="contact-row">
          <Icon icon={icons.headset} size="md" />
          <a href={`tel:${t('attention.phone').replaceAll('-', '')}`}>{t('attention.phone')}</a>
        </div>
      </div>
    </div>
  );
};

UserNotFoundChannels.propTypes = {
  className: PropTypes.string,
};
