import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RequestRegistrationEmail } from '../shared/auth';
import { minHeight } from '../assets/styles';
import config from '../config';
import history from '../history';
import BackgroundShapes from '../shared/components/BackgroundShapes';
import { PublicHeader } from '../shared/header/PublicHeader';
import PublicFooter from '../shared/footer/PublicFooter';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';

/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  ...minHeight,
  paddingTop: 24,
  'h3.title': {
    marginTop: 0,
  },
};

// eslint-disable-next-line no-shadow
const InnerRequestRegistrationEmail = ({ menuClose }) => {
  const { t } = useSelfServiceTranslation();
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToLogin = () => history.push(t('routes.auth.login'));
  const pushSendOk = () => history.push(t('routes.auth.registerSentEmail'));
  const pushRegisterMyself = () => history.push(t('routes.auth.registerSendEmail'));

  const texts = {
    tips: {
      title: t('forgotPassword.tips.title'),
      tip1: t('forgotPassword.tips.tip1'),
      tip2: t('forgotPassword.tips.tip2'),
      tip3: {
        first: t('forgotPassword.tips.tip3.first'),
        second: t('forgotPassword.tips.tip3.second'),
      },
      tip4: {
        first: t('forgotPassword.tips.tip4.first'),
        second: t('forgotPassword.tips.tip4.second'),
        third: t('forgotPassword.tips.tip4.third'),
      },
    },

    form: {
      title: t('register.title'),
      topText: <div>{t('register.description')}</div>,
      email: {
        errorFormat: t('forgotPassword.form.email.errorFormat'),
        errorMandatory: t('forgotPassword.form.email.errorMandatory'),
        label: t('forgotPassword.form.email.label'),
        info: t('forgotPassword.form.email.info'),
      },
    },
    forgotUser: {
      title: t('forgotPassword.forgotUser.title'),
      topText: t('forgotPassword.forgotUser.description'),
    },
    forgotEmail: t('register.recoverUser'),
    submitButton: t('register.submit'),
    success: t('register.success'),

    alert: {
      title: t('forgotPassword.alert.title'),
      button: t('forgotPassword.alert.button'),
      registered: {
        title: t('forgotPassword.alert.registered.title'),
        button: t('forgotPassword.alert.registered.button'),
      },
      sent: t('forgotPassword.alert.sent'),
    },
    apiErrorMsg: t('forgotPassword.apiErrorMsg'),
  };
  const attention = {
    customerSupport: t('attention.customerSupport'),
    phone: t('attention.phone'),
    whatsapp: t('attention.whatsapp'),
  };

  /** @TODO not used */
  const onUnhandledError = () => {};

  /** @note :userId, :email, :token and :name params will be added to the urlToRedirect by 123auth logic later when invoking the sender */
  const urlToRedirect = `${window.location.origin}${t('routes.auth.partialRegister')}`;

  return (
    <div>
      <BackgroundShapes />
      <PublicHeader menuClose={menuClose} />
      <div css={styles}>
        <RequestRegistrationEmail
          initialEmail={state ? state.email : ''}
          urlToRedirect={urlToRedirect}
          config={attention}
          onBackClick={goToLogin}
          onSuccess={pushSendOk}
          onResend={pushRegisterMyself}
          onUnhandledError={onUnhandledError}
          applicationId={config.applicationId}
          texts={texts}
        />
      </div>
      <PublicFooter />
    </div>
  );
};

InnerRequestRegistrationEmail.propTypes = {
  menuClose: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  menuClose: state.shared.menuClose,
});

export const AgRequestRegistrationEmail = connect(mapStateToProps)(InnerRequestRegistrationEmail);
