import axios from 'axios';
import { domainLocation } from '../domainResolver';

import { getPartner } from '../../utils/useSEOConfig';
import * as actions from './actionTypes';

export const loadContext = () => async (dispatch) => {
  const partner = getPartner();

  try {
    dispatch({
      type: actions.LOAD_CONTEXT,
    });
    const res = await axios.get(`context?country=${domainLocation}&partner=${partner}`);
    dispatch({
      type: actions.LOAD_CONTEXT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: actions.LOAD_CONTEXT_FAIL,
    });
  }
};
