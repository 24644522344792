/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Divider,
  colors,
  bodyFont,
  Icon,
  respondTo,
  Container,
  getWhatsappLink,
} from '@123-front/ui-kit';
import { lazy, Suspense } from 'react';
import { autIcons } from '../../assets/icons';
import envConfig from '../../config';
import { domainLocation } from '../domainResolver';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';

const styles = {
  padding: 16,
  backgroundColor: colors.gray.ultralight,
  bottom: 0,
  width: '100%',

  '.top': {
    img: { height: 40 },
  },
  '.contact': {
    textAlign: 'center',
    a: { ...bodyFont('sm'), display: 'block', padding: 5, lineHeigh: 20 },
  },
  '.menu': { textAlign: 'center', a: { ...bodyFont('sm'), padding: '6px 12px' } },
  '.bottom': {
    textAlign: 'center',
    '.juridical-logos': {
      margin: '28px 0 12px 0',
      img: {
        height: 28,
        margin: '4px 16px',
        maxWidth: '90%',
      },
    },
    '.legal-text': {
      fontSize: '10px',
    },
  },
  [respondTo('md')]: {
    '.only-ph': { display: 'none' },
    '.top': { display: 'inline-block' },
    '.contact > a': { display: 'inline-block' },
    '.contact, .menu': {
      display: 'inline-block',
      marginLeft: 72,
      verticalAlign: 'bottom',
    },
  },
};

const loadFooterNavMenu = (location) => {
  const FooterNavMenu = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/FooterNavMenu`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <FooterNavMenu />;
};

const loadFooterLegal = (location) => {
  const FooterLegal = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/FooterLegal`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <FooterLegal />;
};

const Footer = () => {
  const { t } = useSelfServiceTranslation();

  return (
    <footer css={styles}>
      <Container>
        <section className="top">
          <a href={t('footer.top.home')} title={t('123SeguroTitle')}>
            <img
              className="logo"
              src={`${envConfig.brandingS3URL}/logotypes/123/color/logo_123.svg`}
              alt={t('123SeguroTitle')}
              title={t('123SeguroTitle')}
            />
          </a>
        </section>
        <Divider className="only-ph" />
        <section className="contact">
          <a href={`mailto:${t('attention.customerSupport')}`}>
            <Icon size="20" icon={autIcons.email} /> {t('attention.customerSupport')}
          </a>
          <a
            href={getWhatsappLink(t('attention.whatsapp'))}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon size="20" icon={autIcons.whatsapp} /> {t('attention.whatsapp')}
          </a>
        </section>
        <Divider className="only-ph" />
        <Suspense fallback={<div />}>{loadFooterNavMenu(domainLocation)}</Suspense>
        <Divider />
        <Suspense fallback={<div />}>{loadFooterLegal(domainLocation)}</Suspense>
      </Container>
    </footer>
  );
};

export default Footer;
