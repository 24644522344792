import axios from 'axios';
import { domainLocation } from '../domainResolver';

export const AuthService = {
  sendPasswordResetEmail: (mail, urlToRedirect) => {
    return axios.post('auth/users/mail/password', {
      mail,
      urlToRedirect,
      country: domainLocation,
    });
  },
  sendRegisterMail: (mail, urlToRedirect) => {
    return axios.post('auth/users/mail/register', {
      mail,
      urlToRedirect,
      country: domainLocation,
    });
  },
  verifyUserToken: (userId) => {
    return axios.get(`auth/users/${userId}/password/token`);
  },
  login: (userLogin) => {
    return axios.post('auth/users/token', userLogin);
  },
  newPassword: (userId, password, token) => {
    return axios.put(`auth/users/${userId}`, { token, password });
  },
  passwordReset: (userId, token, password) => {
    return axios.patch(`auth/users/${userId}/password/${token}`, { password });
  },
};
