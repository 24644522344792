import { connect } from 'react-redux';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import {
  Divider,
  titleFont,
  colors,
  icons,
  Icon,
  Container,
  Row,
  Column,
  rowAlign,
  respondTo,
} from '@123-front/ui-kit';
import { carPropTypes } from '../../cars/store/reducer-proptypes';
import { findCarByPlate } from '../../cars/car.utils';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  padding: '24px 16px 0 16px',
  [respondTo('lg')]: { padding: '0 24px' },
  '.row-headline': {
    ...rowAlign('center'),
  },
  h1: {
    position: 'relative',
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
    padding: '0 40px',
  },
  '.link-back': {
    position: 'absolute',
    left: 0,
    top: '50%',
    marginTop: -12,
    fontSize: 0,
    lineHeight: 24,
  },
  '.divider': {
    margin: '0 -24px',
  },
  '.iframe-form': {
    width: '100vw',
    marginLeft: '-16px',
    position: 'fixed',
    height: 'calc(100vh - 175px)',
    background: '#fff',
    [respondTo('lg')]: { marginLeft: '-24px', height: 'calc(100vh - 290px)' },
  },
};

const ClaimCrashForm = ({ car }) => {
  const { t } = useSelfServiceTranslation();
  return (
    <div>
      {car ? (
        <div>
          <SelfmanagmentNavbar plate={car.plate} activeTab={NavtabId.SINISTERS} />
          <div css={styles}>
            <Container>
              <Row internal={false} className="row-headline">
                <Column mobile={12} desktop={4}>
                  <h1>
                    <Link
                      className="link-back"
                      to={t('routes.dashboard.claimCrash', { plate: car.plate.toLowerCase() })}
                    >
                      <Icon icon={icons.arrowBack} size="24" color={colors.cyan.dark} />
                    </Link>
                    <span>{t('claimCrash.form.title')}</span>
                  </h1>
                </Column>
              </Row>
            </Container>
            <Divider className="divider" />
            <iframe
              className="iframe-form"
              src={car.company.sinisterFormLink}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title={t('claimCrash.form.title')}
            >
              {t('claimCrash.form.loading')}
            </iframe>
          </div>
        </div>
      ) : null}
    </div>
  );
};

ClaimCrashForm.propTypes = {
  car: carPropTypes,
};
const mapStateToProps = (state, ownProps) => {
  return {
    car: findCarByPlate(state.cars.cars, ownProps.match.params.plate),
  };
};

export default connect(mapStateToProps)(ClaimCrashForm);
