/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { bodyFont, titleFont, colors, colorTheme, respondTo } from '@123-front/ui-kit';
import { useDispatch } from 'react-redux';

import { PublicHeader } from '../shared/header/PublicHeader';
import Footer from '../shared/footer/Footer';
import { AppDownloadButtons } from '../shared/components/AppDownloadButtons';
import { minHeight } from '../assets/styles';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { analyticsPushEvent } from '../shared/analytics/actions';
import { WebAccess } from './components/WebAccess';
import { Hero } from './components/Hero';
import { DotsBackground } from './components/DotsBackground';

const styles = {
  [respondTo('md')]: {
    '.webaccess-sm': {
      display: 'none',
    },
    '.webaccess-md': {
      display: 'flex',
      marginTop: 50,
    },
  },
  [respondTo('xs', 'md')]: {
    '.webaccess-sm': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 50,
    },
    '.webaccess-md': {
      display: 'none',
    },
  },

  '.content-container': {
    display: 'flex',
    overflow: 'hidden',
    [respondTo('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      padding: '50px 116px',
    },
    [respondTo('xs', 'md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 0',
    },

    '.content': {
      [respondTo('md')]: {
        maxWidth: 500,
        padding: '0 20px 0 0',
      },
      [respondTo('xs', 'md')]: {
        padding: '0 16px',
      },

      h1: {
        ...titleFont('lg', 'serif'),
        color: colors.gray.darkest,
        fontWeight: 'bold',
        marginBottom: 40,
        [respondTo('xs', 'md')]: {
          textAlign: 'center',
          marginBottom: 24,
        },
      },
      p: {
        ...bodyFont('lg', 'sans'),
        color: colorTheme.mute,
        margin: 0,
        [respondTo('xs', 'md')]: {
          textAlign: 'center',
        },
      },
      '.buttons': {
        marginTop: 40,
        [respondTo('xs', 'md')]: {
          marginTop: 20,
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
  },
};

export function DownloadApp() {
  const { t } = useSelfServiceTranslation();
  const dispatch = useDispatch();

  const onDownloadAndroid = () => {
    dispatch(analyticsPushEvent('trackEvent', 'download-app-android', 'app', null, 'autogestion'));
  };
  const onDonwloadIos = () => {
    dispatch(analyticsPushEvent('trackEvent', 'download-app-ios', 'app', null, 'autogestion'));
  };

  return (
    <div css={styles}>
      <DotsBackground />

      <PublicHeader menuDisabled menuClose />

      <section className="content-container" css={minHeight}>
        <div className="content">
          <h1>{t('landings.downloadApp.title')}</h1>
          <p>{t('landings.downloadApp.description')}</p>
          <p>{t('landings.downloadApp.download')}</p>
          <AppDownloadButtons
            className="buttons"
            onDownloadAndroid={onDownloadAndroid}
            onDownloadIos={onDonwloadIos}
          />

          <WebAccess className="webaccess-md" />
        </div>

        <Hero />
      </section>

      <WebAccess className="webaccess-sm" />

      <Footer />
    </div>
  );
}
