/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Alert,
  base,
  baseStyles,
  box,
  Button,
  colors,
  colorTheme,
  Divider,
  Icon,
  icons,
  Input,
  Loader,
  subtitleFont,
  titleFont,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { PaymentTypes } from '../payment-method-types.enum';
import { initCyberSource } from '../services/init-cybersource';
import { savePaymentMethod } from '../services/save-payment-method';

const elements = {
  cardNumber: 'card-number-container',
  cardNumberPlaceholder: 'Ex.: 4444 6666 8888 0000',
  securityCode: 'security-code-container',
  securityCodePlaceholder: 'Ex.: 123',
  dueDate: 'due-date',
  cardHolder: 'card-holder',
  payButton: 'pay-button',
};

const microformStyles = {
  input: {
    'font-size': '16px',
    'font-family': base.fontFamily,
    color: colorTheme.textDefault,
  },
  '::placeholder': {
    color: colorTheme.textPlaceholder,
  },
  ':focus': { color: colorTheme.textDefault },
  ':disabled': { cursor: 'not-allowed' },
  valid: { color: colorTheme.textDefault },
  invalid: { color: colors.red.dark },
};

const styles = {
  ...box,
  paddingTop: 16,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 16,
  '.detail': {
    ...subtitleFont('sm'),
    textTransform: 'uppercase',
    color: colors.gray.dark,
  },
  '.coverage-detail': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  '.coverage-detail-text': {
    textTransform: 'uppercase',
    fontFamily: base.fontFamily,
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '17px',
    marginLeft: 9,
  },
  '.coverage-detail-amount': {
    fontFamily: base.fontFamily,
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '16px',
  },
  '.form-title': titleFont('xs', 'serif'),
  '.form-content': {
    paddingTop: 19,
    paddingLeft: 81,
    paddingRight: 81,
    paddingBottom: 24,
  },
  label: {
    textTransform: 'uppercase',
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
    marginBottom: 4,
    color: colors.gray.dark,
  },
  '.flex-microform': {
    ...baseStyles.input,
    height: 48,
  },
  '.error-message': {
    marginBottom: 16,
  },
  '.recurring-payments-alert': {
    marginBottom: 23,
  },
};

const formatAmount = (amount) => {
  const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format;
  return formatter(amount);
};

export const CyberSourceBrick = ({ linkId, country, channel, amount }) => {
  const { t, ready } = useSelfServiceTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (error, formValues) => {
      if (error) {
        setErrorMessage(error.message);
        setErrorFields(error.fields);
        return;
      }

      setSubmitting(true);

      const saveResult = await savePaymentMethod({
        type: PaymentTypes.CYBERSOURCE_PRU_BR,
        linkId,
        country,
        channel,
        token: formValues.token,
        contextKey: formValues.contextKey,
        brandName: formValues.brandName,
        cardHolder: formValues.cardHolder,
        dueDate: formValues.dueDate,
      });

      setSubmitting(false);

      if (!saveResult.ok) {
        setErrorMessage(saveResult.errorMessage);
        setErrorFields(saveResult.errorFields);
        return;
      }

      window.location = saveResult.redirectUrl;
    },
    [country, channel, linkId],
  );

  useEffect(() => {
    initCyberSource({ styles: microformStyles, elements, onSubmit }).then((result) => {
      if (!result.ok) {
        setErrorMessage(result.errorMessage);
      }
    });
  }, [onSubmit]);

  if (!ready) {
    return <Loader />;
  }

  return (
    <div css={styles}>
      <p className="detail">detalhe</p>
      <Divider />
      <div className="coverage-detail">
        <span>
          <Icon icon={icons.basket} color={colors.gray.dark} size={24} />
          <span className="coverage-detail-text">prudential do brasil</span>
        </span>
        <span className="coverage-detail-amount">{formatAmount(amount)} / mês</span>
      </div>
      <Divider />
      <div className="form-content">
        {errorMessage && <Alert className="error-message" type="error" text={t(errorMessage)} />}
        <p className="form-title">Insira os dados do cartão</p>
        <Input
          id={elements.cardNumber}
          name="cardNumber"
          label={t('paymentMethods.createPaymentMethod.cardNumber')}
          tag="div"
          error={errorFields?.cardNumber && t(errorFields.cardNumber)}
        />
        <Input
          id={elements.dueDate}
          name="dueDate"
          label={t('paymentMethods.createPaymentMethod.dueDate')}
          mask="99/99"
          maskChar="_"
          placeholder="Ex.: 11/25"
          error={errorFields?.dueDate && t(errorFields.dueDate)}
        />
        <Input
          id={elements.securityCode}
          name="securityCode"
          label={'Código de segurança'}
          tag="div"
          error={errorFields?.securityCode && t(errorFields.securityCode)}
        />
        <Input
          id={elements.cardHolder}
          name="cardHolder"
          label={t('paymentMethods.createPaymentMethod.holderName')}
          placeholder="Como escrito no cartão"
          error={errorFields?.cardHolder && t(errorFields.cardHolder)}
        />
        <Button id={elements.payButton} block color="success" disabled={isSubmitting}>
          Pagar
        </Button>
      </div>
    </div>
  );
};

CyberSourceBrick.propTypes = {
  linkId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  channel: PropTypes.string,
  amount: PropTypes.number,
};
