import { PaymentTypes } from './payment-method-types.enum';

export const validatePaymentType = (values, messages) => {
  let errors = {};
  if (values.type.type === PaymentTypes.CREDIT_CARD) {
    errors = validateCardType(values, messages);
  } else if (values.type.type === PaymentTypes.BANK_ACCOUNT) {
    errors = validateAccountType(values, messages);
  } else {
    errors.type = messages.typeRequired;
  }

  return errors;
};

const validateCardType = (values, messages) => {
  const errors = {};
  if (!values.brand) {
    errors.brand = messages.brandRequired;
  }

  if (!values.issuer) {
    errors.issuer = messages.issuerRequired;
  }

  return errors;
};

const validateAccountType = (values, messages) => {
  const errors = {};
  if (!values.bank) {
    errors.bank = messages.issuerRequired;
  }

  if (!values.accountType) {
    errors.accountType = messages.accountTypeRequired;
  }

  return errors;
};
