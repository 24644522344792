import { respondTo, colors, subtitleFont, titleFont, box } from '@123-front/ui-kit';
import config from '../config';

export const stylesLoginLanding = {
  minHeight: 'calc(100vh - 488px)',
  padding: '32px 16px 0 16px',
  [respondTo('lg')]: {
    padding: '40px 24px 100px 24px',
    background: `transparent url("${config.brandingS3URL}/miscellaneous/misc_deco-myinsurances.svg") no-repeat top right`,
  },

  '.row-headline': {
    marginBottom: 24,
    span: {
      ...subtitleFont('md'),
      marginBottom: '0.5em',
      textTransform: 'uppercase',
    },
    h2: {
      ...titleFont('lg', 'serif'),
      marginBottom: '0.5em',
      color: colors.gray.darkest,
    },
    [respondTo('lg')]: {
      position: 'relative',
      '&:before': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: 24,
        left: -216,
        width: 192,
        height: 128,
        backgroundImage: `url("${config.brandingS3URL}/miscellaneous/misc_dots-4x4.svg")`,
      },
    },
  },

  '.row-featsnsignin': {
    '&:after': {
      display: 'block',
      content: '" "',
      clear: 'both',
    },
  },

  '.col-signin': {
    margin: '0 -16px 60px -16px',
    [respondTo('lg')]: {
      margin: '57px 0 0 0',
      float: 'right',
      width: '40%',
      position: 'sticky',
      top: 120,
      '> section': {
        borderRadius: 4,
      },
    },
  },

  '.col-features': {
    [respondTo('lg')]: {
      float: 'left',
      width: '50%',
    },
  },

  '.section-featsmain': {
    h3: {
      display: 'none',
    },
    ul: {
      listStyleType: 'none',
      padding: 0,
      margin: '0 0 60px 0',
      textAlign: 'center',
    },
    span: {
      display: 'block',
    },
    li: {
      display: 'block',
      border: `1px solid ${colors.gray.lighten}`,
      padding: '24px 16px',
      marginTop: -1,
      '&:first-of-type': { borderRadius: '4px 4px 0 0' },
      '&:last-child': { borderRadius: '0 0 4px 4px' },
    },
    img: {
      width: 80,
      marginBottom: 16,
    },
    p: {
      color: colors.gray.darkest,
      margin: 0,
    },
    [respondTo('lg')]: {
      h3: {
        display: 'block',
        ...titleFont('sm', 'serif'),
        color: colors.gray.darkest,
      },
      span: {
        display: 'inline-block',
        width: '50%',
        '&:first-of-type': {
          marginLeft: -1,
          'li:first-of-type': { borderRadius: '4px 0 0 0' },
          'li:last-child': { borderRadius: '0 0 4px 0' },
        },
        '&:last-child': {
          marginLeft: -1,
          'li:first-of-type': { borderRadius: '0 4px 0 0' },
          'li:last-child': { borderRadius: '0 0 4px 0' },
        },
      },
      li: {
        padding: '32px 16px 40px 16px',
      },
    },
  },

  '.section-featsmore': {
    textAlign: 'center',
    h4: {
      ...titleFont('sm', 'sans'),
      color: colors.gray.darkest,
    },
    ul: {
      listStyleType: 'none',
      padding: 0,
      margin: '0 0 80px 0',
      color: colors.gray.darkest,
    },
    li: {
      display: 'inline',
      '&:after': {
        display: 'inline',
        content: '". "',
      },
    },
    [respondTo('lg')]: {
      textAlign: 'left',
      h4: {
        ...titleFont('xs', 'sans'),
        color: colors.gray.darkest,
      },
      li: {
        position: 'relative',
        display: 'block',
        paddingLeft: 16,
        marginBottom: 8,
        '&:after': { display: 'none' },
        '&:before': {
          position: 'absolute',
          display: 'inline-block',
          content: '"•"',
          color: colors.blue.medium,
          left: 0,
        },
      },
    },
  },

  '.section-featsimg': {
    position: 'relative',
    img: {
      position: 'relative',
      width: '100%',
      maxWidth: 592,
      zIndex: 2,
    },
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 132,
      left: -22,
      width: 192,
      height: 128,
      backgroundImage: `url("${config.brandingS3URL}/miscellaneous/misc_dots-4x4.svg")`,
    },
    [respondTo('lg')]: {
      '&:after': {
        top: 224,
        left: -66,
      },
    },
  },

  '.cnt-banner': {
    position: 'relative',
    padding: '96px 16px 34px 16px',
    background: `${colors.blue.lightest} url("https://branding.s3.us-west-2.amazonaws.com/shapes/shape_curve-bottom-blue.svg") no-repeat bottom center`,
    backgroundSize: '100% auto',
    textAlign: 'center',
    margin: '100px -16px 0 -16px',
    h4: {
      ...titleFont('lg', 'serif'),
      color: colors.gray.darkest,
      marginBottom: '0.5em',
    },
    p: {
      marginBottom: 24,
    },
    button: {
      minWidth: 140,
      margin: '0 12px 16px 0',
    },
    [respondTo('lg')]: {
      backgroundSize: '50% auto',
      borderRadius: 4,
      margin: '100px 0 0 0',
      p: {
        marginBottom: 40,
      },
    },
  },
  '.banner-logo': {
    ...box,
    position: 'absolute',
    top: -20,
    left: '50%',
    marginLeft: -50,
    width: 100,
    height: 100,
    borderRadius: '50%',
    img: { height: 60, marginTop: 16 },
  },
};
