import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { colors, respondTo, titleFont, Container, Row, rowAlign } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { carPropTypes } from '../../cars/store/reducer-proptypes';
import { findCarByPlate } from '../../cars/car.utils';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { domainLocation } from '../../shared/domainResolver';
import { EveryoneIsFine } from './components/EveryoneIsFine';
/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  padding: '24px 16px 40px 16px',
  [respondTo('lg')]: {
    padding: '0 24px 72px 24px',
  },
  '.row': {
    ...rowAlign('center'),
  },
  h1: {
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
  },
};

const loadClaimCrash = (car, location) => {
  const ClaimCrashContent = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/ClaimCrashContent`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <ClaimCrashContent car={car} />;
};

const ClaimCrash = ({ car }) => {
  return (
    <div>
      {car && (
        <div>
          <SelfmanagmentNavbar plate={car.plate} activeTab={NavtabId.SINISTERS} />
          <div css={styles}>
            <Container>
              <Row internal={false} className="row">
                <Suspense fallback={<div />}>{loadClaimCrash(car, domainLocation)}</Suspense>
                <EveryoneIsFine car={car} />
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

ClaimCrash.propTypes = {
  car: carPropTypes,
};
const mapStateToProps = (state, ownProps) => {
  return {
    car: findCarByPlate(state.cars.cars, ownProps.match.params.plate),
  };
};

export default connect(mapStateToProps)(ClaimCrash);
