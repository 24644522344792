/** @jsxRuntime classic */
/** @jsx jsx */
import { base, colors, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { PaymentTypes } from '../payment-method-types.enum';
import { BankIssuer } from './BankIssuer';
import { HolderDetail } from './HolderDetail';
import { PaymentMethodBox } from './PaymentMethodBox';
import { PaymentMethodNumber } from './PaymentMethodNumber';

const fontColor = 'white';

const styles = {
  margin: 0,
  [respondTo('xs', 'md')]: {
    marginRight: 0,
  },
  '.brand-issuer-state': {
    paddingBottom: 10,
  },
  '.brand-issuer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '.brand-details': {
    display: 'flex',
    alignItems: 'center',
  },
  '.brand': {
    height: 40,
  },
  '.bold-font': {
    fontFamily: base.fontFamily,
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  '.type': { paddingLeft: 7 },
  '.holder-details': {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    justifyContent: 'space-between',
  },
  '.enabled-box': {
    transition: 'background 0.5s',
    background: colors.blue.dark,
    border: `1px solid ${colors.blue.dark}`,
  },
  '.disabled-box': {
    background: colors.gray.light,
  },
};

const paymentMethodTypeMapping = {
  CREDIT_CARD: 'crédito',
  DEBIT_CARD: 'débito',
};

const CardDetails = ({
  issuer,
  brand,
  type,
  number,
  holderName,
  holderIdType,
  holderIdNumber,
  expirationMonth,
  expirationYear,
  disabled,
}) => {
  const { t, ready } = useSelfServiceTranslation();

  return ready ? (
    <div css={styles}>
      <PaymentMethodBox className={!disabled ? 'enabled-box' : 'disabled-box'}>
        <div className="brand-issuer-state">
          <div className="brand-issuer">
            <div className="brand-details">
              {brand ? (
                <img
                  className="brand"
                  src={`https://branding.s3.us-west-2.amazonaws.com/logotypes/payments/white/cardbrand_${brand}.svg`}
                  alt={brand}
                />
              ) : (
                <span className="bold-font">{t('paymentMethods.createPaymentMethod.brand')}</span>
              )}
              <span className="bold-font type">
                {paymentMethodTypeMapping[type] || 'desconocido'}
              </span>
            </div>
            <BankIssuer color="white" name={issuer} />
          </div>
        </div>
        <PaymentMethodNumber color={fontColor} value={number} />
        <div className="holder-details">
          <HolderDetail
            title="VTO."
            value={formatExpirationDate(expirationMonth, expirationYear)}
            color={fontColor}
          />
          <HolderDetail
            title={t('paymentMethods.createPaymentMethod.holderName')}
            value={holderName}
            color={fontColor}
          />
          <HolderDetail title={holderIdType} value={holderIdNumber} color={fontColor} />
        </div>
      </PaymentMethodBox>
    </div>
  ) : null;
};

const formatExpirationDate = (month, year) => {
  if (!month && !year) {
    return 'MM/AA';
  }
  let formattedYear = year.toString().substr(-2);
  let formattedMonth = month.toString();

  if (month < 10) {
    formattedMonth = '0' + formattedMonth;
  }

  return formattedMonth + '/' + formattedYear;
};

CardDetails.propTypes = {
  type: PropTypes.oneOf([PaymentTypes.CREDIT_CARD, PaymentTypes.BANK_ACCOUNT]),
  issuer: PropTypes.string,
  brand: PropTypes.string,
  number: PropTypes.string,
  holderName: PropTypes.string,
  holderIdType: PropTypes.string,
  holderIdNumber: PropTypes.string,
  expirationMonth: PropTypes.number,
  expirationYear: PropTypes.number,
  disabled: PropTypes.bool,
};

export default CardDetails;
