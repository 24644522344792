/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { subtitleFont, colors, icons, Icon, Label } from '@123-front/ui-kit';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../../../config';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { InspectionStatus } from '../../../shared/inspection.enum';
import { cardStyles } from './cardStyles';

const styles = {
  ...cardStyles,
  '.card-title': {
    ...cardStyles['.card-title'],
  },
  '.card-plate': {
    ...subtitleFont('sm', 'sans'),
    color: colors.gray.dark,
    marginBottom: 0,
  },
  '.inspection-label': {
    marginTop: 4,
  },
};

const ProductCard = ({ logo, title, plate, inspection }) => {
  const { t } = useSelfServiceTranslation();
  const toInspect = inspection?.status === InspectionStatus.TO_INSPECT;
  const toReview = inspection?.status === InspectionStatus.SENT;
  const approved = inspection?.status === InspectionStatus.APPROVED;
  const [haveIcon, setHaveIcon] = useState(true);
  return (
    <div css={styles}>
      <Link className="link" to={t('routes.dashboard.circulation', { plate: plate.toLowerCase() })}>
        <div className="container">
          {haveIcon ? (
            <img
              className="card-logo"
              alt={logo}
              src={`${config.brandingS3URL}/logotypes/cars/logo_${logo}.svg`}
              onError={() => setHaveIcon(false)}
            />
          ) : (
            <img
              className="card-logo"
              alt="Logo"
              src={`${config.brandingS3URL}/illustration/illus_placeholder-car.png`}
            />
          )}
          <div className="card-details">
            <h4 className="card-title">{title}</h4>
            <h3 className="card-plate">
              {t('productSelection.card.plate')} {plate.toUpperCase()}
            </h3>
            {toInspect && (
              <Label className="inspection-label" variant="danger">
                {t('productSelection.card.inspection.toInspect')}
              </Label>
            )}
            {toReview && (
              <Label className="inspection-label" variant="info">
                {t('productSelection.card.inspection.toReview')}
              </Label>
            )}
            {approved && (
              <Label className="inspection-label" variant="success">
                {t('productSelection.card.inspection.approved')}
              </Label>
            )}
          </div>
        </div>
        <Icon icon={icons.chevronRight} color={colors.cyan.dark} size={28} className="icon" />
      </Link>
    </div>
  );
};

ProductCard.defaultProps = {
  logo: '',
  title: '',
  plate: '',
};

ProductCard.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string,
  plate: PropTypes.string,
  inspection: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default ProductCard;
