import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ButtonWrapper } from '@123-front/ui-kit';
import { androidAppLink, iosAppLink } from '../links';

const styles = {
  '.download-button': {
    height: 40,
    img: {
      height: 'inherit',
    },
    '&:first-of-type': {
      marginRight: 16,
    },
  },
};

export const AppDownloadButtons = ({ className = '', onDownloadAndroid, onDownloadIos }) => {
  const handleStoreRedirect = (storeUrl) => {
    window.open(storeUrl);
  };

  const gotoStoreAndroid = () => {
    if (onDownloadAndroid) {
      onDownloadAndroid();
    }
    handleStoreRedirect(androidAppLink);
  };

  const gotoStoreIos = () => {
    if (onDownloadIos) {
      onDownloadIos();
    }
    handleStoreRedirect(iosAppLink);
  };

  return (
    <div css={styles} className={className}>
      <ButtonWrapper className="download-button" onClick={gotoStoreAndroid}>
        <img
          alt="android"
          src="https://branding.s3.us-west-2.amazonaws.com/logotypes/badges/badge_store_android.svg"
        />
      </ButtonWrapper>
      <ButtonWrapper className="download-button" onClick={gotoStoreIos}>
        <img
          alt="ios"
          src="https://branding.s3.us-west-2.amazonaws.com/logotypes/badges/badge_store_ios.svg"
        />
      </ButtonWrapper>
    </div>
  );
};

AppDownloadButtons.propTypes = {
  className: PropTypes.string,
  onDownloadAndroid: PropTypes.func,
  onDownloadIos: PropTypes.func,
};
