export const getExpirationMonth = (dueDate) => {
  if (!dueDate || dueDate.includes('_')) return null;
  if (dueDate[0] === '0') return Number(dueDate[1]);
  return Number(dueDate.substring(0, 2));
};

export const getExpirationYear = (dueDate) => {
  if (!dueDate || dueDate.includes('_')) return null;
  return Number(dueDate.substring(3, 5));
};
