import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { jsx } from '@emotion/core';
import { Container, Row, Column, Button } from '@123-front/ui-kit';
import { SignIn } from '../shared/auth';
import config from '../config';
import history from '../history';
import { PublicHeader } from '../shared/header/PublicHeader';
import PublicFooter from '../shared/footer/PublicFooter';

import { isAvailableCountry } from '../isAvailableCountry';
import { domainLocation } from '../shared/domainResolver';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { getSelectedBenefits } from '../utils/benefits/benefits.service';
import { androidAppLink, iosAppLink } from '../shared/links';
import { stylesLoginLanding } from './LoginLanding.styles';
import { signIn } from './store/actions';
/** @jsxRuntime classic */
/** @jsx jsx */

// eslint-disable-next-line no-shadow
const LoginLanding = ({ signIn, menuClose }) => {
  const { t, basePath } = useSelfServiceTranslation();
  const texts = {
    form: {
      title: t('login.form.title'),
      email: {
        label: t('login.form.email.label'),
        info: t('login.form.email.info'),
        errorMandatory: t('login.form.email.errorMandatory'),
        errorFormat: t('login.form.email.errorFormat'),
      },
      password: {
        label: t('login.form.password.label'),
        info: t('login.form.password.info'),
        errorMandatory: t('login.form.password.errorMandatory'),
        errorFormat: t('login.form.password.errorFormat'),
      },
      button: t('login.form.button'),
      forgotPassword: t('login.form.forgotPassword'),
      errors: {
        wrongCredentials: t('login.form.errors.wrongCredentials'),
        needHelp: t('login.form.errors.needHelp'),
        unexpected: t('login.form.errors.unexpected'),
        notActive: t('login.form.errors.notActive'),
        activeAccountHelp: t('login.form.errors.activeAccountHelp'),
      },
    },
    registerTitle: t('login.registerTitle'),
  };
  const onForgotPassword = () => history.push(t('routes.auth.forgotPassword'));
  const onRegistermeClick = () => history.push(t('routes.auth.registerSendEmail'));
  const loginURL = () => history.push(t('routes.auth.login'));
  const onSubmit = ({ token, mail }) => {
    signIn(token, mail, basePath);
  };

  //TODO: si los links para las appstore varian segun pais, refactorizar como corresponda
  return (
    <div>
      <PublicHeader menuClose={menuClose} />
      <div css={stylesLoginLanding}>
        <Container>
          <Row className="row-headline">
            <Column mobile={12} desktop={6}>
              <span>{t('headline.insurance')}</span>
              <h2>{t('headline.title')}</h2>
              <p>{t('headline.subtitle')}</p>
            </Column>
          </Row>

          <div className="row-featsnsignin">
            <div className="col-signin">
              <SignIn
                texts={texts}
                config={{ whatsapp: t('attention.whatsapp') }}
                onSubmit={onSubmit}
                applicationId={config.applicationId}
                onForgotPasswordClick={onForgotPassword}
                onActivateAccountClick={onRegistermeClick}
                onRegistermeClick={onRegistermeClick}
                showForm={isAvailableCountry(domainLocation)}
                androidStoreLink={androidAppLink}
                iosStoreLink={iosAppLink}
              />
            </div>

            <div className="col-features">
              <div className="section-featsmain">
                <h3>{t('features.featsmain')}</h3>
                <ul>
                  <span>
                    <li>
                      <img src={`${config.brandingS3URL}/images/img_docs.svg`} alt="icono poliza" />
                      <p>{t('features.policy')}</p>
                    </li>
                    <li>
                      <img
                        src={`${config.brandingS3URL}/images/img_car-assistance.svg`}
                        alt="icono auxilio"
                      />
                      <p>{t('features.assistance')}</p>
                    </li>
                  </span>
                  <span>
                    <li>
                      <img
                        src={`${config.brandingS3URL}/images/img_car-sinister.svg`}
                        alt="icono siniestro"
                      />
                      <p>{t('features.sinister')}</p>
                    </li>
                    <li>
                      <img
                        src={`${config.brandingS3URL}/images/img_doc-receipt.svg`}
                        alt="icono pagos"
                      />
                      <p>{t('features.payment')}</p>
                    </li>
                  </span>
                </ul>
              </div>

              <div className="section-featsmore">
                <h4>{t('features.featsmore.title')}</h4>
                <ul>
                  {getSelectedBenefits(domainLocation).map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>

              <div className="section-featsimg">
                <img
                  src={`${config.brandingS3URL}/photos/photo_ag_myinsurances.png`}
                  alt="Foto autogestion"
                />
              </div>
            </div>
          </div>

          <Row>
            <Column mobile={12} desktop={12}>
              <div className="cnt-banner">
                <div className="banner-logo">
                  <img
                    src={`${config.brandingS3URL}/logotypes/123/color/logo_123.svg`}
                    alt="123Seguro"
                  />
                </div>
                <h4>{t('banner.digitalExperience')}</h4>
                <p>{t('banner.onlineManagment')}</p>
                <Button variant="primary" color="interactive" onClick={loginURL}>
                  {t('banner.login')}
                </Button>
                <Button color="interactive" variant="secondary" onClick={onRegistermeClick}>
                  {t('banner.register')}
                </Button>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
      <PublicFooter />
    </div>
  );
};

LoginLanding.propTypes = {
  signIn: PropTypes.func.isRequired,
  menuClose: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  menuClose: state.shared.menuClose,
});

export default connect(mapStateToProps, { signIn })(LoginLanding);
