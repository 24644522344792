/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Button,
  Checkbox,
  Column,
  Container,
  Icon,
  Input,
  OptionSelector,
  Row,
  bodyFont,
  colorTheme,
  icons,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getChannelData } from '../ephemeral-link/channel-branding';
import { Country } from '../shared/country.enum';
import { FixedFooter } from './FixedFooter';
import documentTypes from './document-types.json';
import { PaymentTypes } from './payment-method-types.enum';

const styles = {
  padding: '0',
  '.form-container': {
    display: 'flex',
    webkitBoxPack: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '359px',
    padding: 0,
    marginBottom: 100,
    label: {
      textTransform: 'uppercase',
    },
    '& > div > div > div': {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
      '.select': {
        width: 'auto',
      },
    },
  },
  '.error': {
    ...bodyFont('sm'),
    color: colorTheme.hintDanger,
  },
};

const FormCard = ({ values, errors, setValues, prevHandler, onSave }) => {
  const { t, ready, i18n } = useTranslation('paymentMethods');
  const countryCode = useMemo(() => i18n.language.toUpperCase(), [i18n]);
  const params = useParams();
  const { includeTermsConditions } = getChannelData(params.channel);

  return ready ? (
    <form css={styles}>
      <Container className="form-container">
        <Column>
          <Row>
            {values.type.type === PaymentTypes.CREDIT_CARD && (
              <Input
                value={values.cardNumber}
                name="cardNumber"
                label={t('paymentMethods.createPaymentMethod.cardNumber')}
                mask="9999 9999 9999 9999"
                placeholder="Ej.: 4444 6666 8888 0000"
                error={errors && errors.cardNumber}
                onChange={(evt) => setValues({ ...values, cardNumber: evt.target.value })}
              />
            )}
            {values.type.type === PaymentTypes.BANK_ACCOUNT && (
              <Input
                value={values.accountNumber}
                name="accountNumber"
                label={t('paymentMethods.createPaymentMethod.accountNumber')}
                mask={getMask('accountNumber', countryCode)}
                placeholder="Ej.: 1234567891234567891234"
                error={errors && errors.accountNumber}
                onChange={(evt) => setValues({ ...values, accountNumber: evt.target.value })}
              />
            )}
          </Row>
          {values.type.type === PaymentTypes.CREDIT_CARD && (
            <Row>
              <Input
                value={values.dueDate}
                name="dueDate"
                label={t('paymentMethods.createPaymentMethod.dueDate')}
                mask="99/99"
                maskChar="_"
                placeholder="Ej.: 11/25"
                error={errors && errors.dueDate}
                onChange={(evt) => setValues({ ...values, dueDate: evt.target.value })}
              />
            </Row>
          )}
          <Row>
            <Input
              value={values.cardHolder}
              name="cardHolder"
              label={t('paymentMethods.createPaymentMethod.holderName')}
              placeholder="Ej.: Ariel López"
              error={errors && errors.cardHolder}
              onChange={(evt) => setValues({ ...values, cardHolder: evt.target.value })}
            />
          </Row>
          <Row>
            <OptionSelector
              label={t('paymentMethods.createPaymentMethod.legalIdType')}
              items={documentTypes[countryCode]}
              selected={documentTypes[countryCode][0]}
              render="name"
              error={errors && errors.legalIdType}
              onChange={(legalIdType) => setValues({ ...values, legalIdType })}
            />
          </Row>
          <Row>
            <Input
              value={values.holderLegalId}
              name="holderLegalId"
              label={t('paymentMethods.createPaymentMethod.holderLegalId')}
              placeholder={t('paymentMethods.createPaymentMethod.legalIdPlaceholder')}
              error={errors && errors.holderLegalId}
              onChange={(evt) => setValues({ ...values, holderLegalId: evt.target.value })}
            />
          </Row>
          {includeTermsConditions ? (
            <Row>
              <Checkbox onChange={(value) => setValues({ ...values, termsConditions: value })}>
                Acepto{' '}
                <a
                  href={`./public/billing/${
                    params.channel ? `${params.channel}/` : ''
                  }terms-conditions`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  términos y condiciones
                </a>
              </Checkbox>
              {errors && errors.termsConditions ? (
                <span className="error">
                  <Icon icon={icons.info} color={colorTheme.hintDanger} /> {errors.termsConditions}
                </span>
              ) : null}
            </Row>
          ) : null}
        </Column>
      </Container>
      <FixedFooter>
        <Button onClick={prevHandler} variant="secondary">
          {t('paymentMethods.createPaymentMethod.prev')}
        </Button>
        <Button onClick={onSave} variant="primary">
          {t('paymentMethods.createPaymentMethod.save')}
        </Button>
      </FixedFooter>
    </form>
  ) : null;
};

FormCard.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  prevHandler: PropTypes.func,
  onSave: PropTypes.func,
};

export default FormCard;

const fieldMasks = {
  [Country.AR.toUpperCase()]: {
    accountNumber: ''.padEnd(22, '9'),
  },
  [Country.CL.toUpperCase()]: {
    accountNumber: ''.padEnd(19, '9'),
  },
};

const getMask = (fieldName, countryCode) =>
  fieldMasks[countryCode] && fieldMasks[countryCode][fieldName]
    ? fieldMasks[countryCode][fieldName]
    : '';
