import axios from 'axios';
import { loadCars } from '../../cars/store/actions';
import history from '../../history';
import { domainLocation } from '../../shared/domainResolver';
import * as actions from './action-types';
import setAuthToken from './setAuthToken';

// signIn user
export const signIn = (token, mail, urlToRedirect) => async (dispatch) => {
  dispatch({
    type: actions.SIGN_IN_SUCCESS,
    payload: { token, mail },
  });

  history.push(urlToRedirect);
};

export const loadUser = () => async (dispatch) => {
  dispatch({
    type: actions.USER_LOAD,
  });
  if (localStorage.token) {
    try {
      setAuthToken(localStorage.token);
      const res = await axios.get(`users?country=${domainLocation}`);
      dispatch(loadCars());
      dispatch({
        type: actions.USER_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      if (err.response.status === 404) {
        dispatch({ type: actions.USER_LOAD_NO_DATA, payload: null });
      } else {
        dispatch({ type: actions.USER_LOAD_FAIL });
      }
      console.error('USER_LOAD_FAIL', err);
    }
  } else {
    dispatch({ type: actions.SIGN_OUT });
  }
};

// signOut user
export const signOut = (urlToRedirect) => (dispatch) => {
  dispatch({
    type: actions.SIGN_OUT,
  });

  history.push(urlToRedirect);
};
