const questions = {
  ar: require('./benefits-ar.mock'),
  br: require('./benefits-br.mock'),
  cl: require('./benefits-cl.mock'),
  co: require('./benefits-co.mock'),
};

export const getSelectedBenefits = (language) => {
  return questions[language]['benefits'];
};
