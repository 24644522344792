import {
  ANALYTICS_FLUSH_DATA,
  ANALYTICS_PUSH_EVENT,
  ANALYTICS_ADD_ADDITIONAL_DATA,
} from './actionTypes';

const INITIAL_STATE = {
  pageAdditionalData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANALYTICS_FLUSH_DATA:
      return INITIAL_STATE;

    case ANALYTICS_ADD_ADDITIONAL_DATA:
      return {
        ...state,
        pageAdditionalData: { ...state.pageAdditionalData, ...action.payload },
      };
    case ANALYTICS_PUSH_EVENT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
