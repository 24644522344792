/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { colors, titleFont } from '@123-front/ui-kit';
import { NewPassword } from './NewPassword';
import { authContainer } from './styles';
import { TokenError } from './TokenError';
import { AuthService } from './AuthService';

const styles = {
  ...authContainer,
  '.title': {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    margin: '24px 0 0 0',
    textAlign: 'center',
  },
};

export const RestorePassword = ({ texts, userId, token, onSubmit, onEnterTo123, config }) => {
  const [tokenError, setTokenError] = useState(false);
  const changePassword = async (password) => {
    try {
      return await AuthService.passwordReset(userId, token, password);
    } catch (error) {
      if (error.response && error.response.data[0].status === 403) {
        setTokenError(true);
      }
      throw error;
    }
  };
  return (
    <section css={styles}>
      {!tokenError ? (
        <NewPassword
          onSubmit={onSubmit}
          changePassword={changePassword}
          texts={texts}
          config={config}
        />
      ) : (
        <TokenError
          msg={texts.form.tokenError}
          onEnterTo123={onEnterTo123}
          title={texts.form.title}
          goToLogin={texts.form.goToLogin}
        />
      )}
    </section>
  );
};

RestorePassword.propTypes = {
  texts: PropTypes.shape({
    form: PropTypes.shape({
      title: PropTypes.string,
      topText: PropTypes.string,
      tokenError: PropTypes.string,
      goToLogin: PropTypes.string,
      button: PropTypes.string,
      password: PropTypes.shape({
        errorMandatory: PropTypes.string,
        errorFormat: PropTypes.string,
        label: PropTypes.string,
        info: PropTypes.string,
      }),
      errors: PropTypes.shape({
        unexpected: PropTypes.string,
      }),
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onEnterTo123: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  config: PropTypes.shape({
    whatsapp: PropTypes.string,
  }).isRequired,
};
