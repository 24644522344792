/** @jsxRuntime classic */
/** @jsx jsx */

import PropTypes from 'prop-types';
import { useState } from 'react';
import { jsx } from '@emotion/core';
import { icons, colors, Input, Icon } from '@123-front/ui-kit';

const styles = {
  '.input-container': {
    display: 'inline-block',
    width: '100%',
    position: 'relative',
    button: { background: 'none', border: 'none', position: 'absolute', top: 28, padding: 0 },
    '.password-view': { right: 8 },
    '.password-strength': { right: 36 },
  },
  input: {
    paddingRight: 64,
  },
};

export const OLD_CHECKS = {
  lowercaseCheck: /[a-z]/g, // The string must contain at least 1 lowercase alphabetical character
  uppercaseCheck: /[A-Z]/g, // The string must contain at least 1 uppercase alphabetical character
  numberCheck: /[1-9]/g, // The string must contain at least 1 numeric character
  specialCheck: /[!@#$%^&-_]/g, // The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
  lengthCheck: /.{12,}/, // The string must be 12 characters or longer
};
export const OLD_MIN = 8;
export const OLD_MIN_STRENGTH = 1;
const oneUpperCase = /[A-Z]/;
const oneLowerCase = /[a-z]/;
const oneDigit = /[0-9]/;
const oneSpecialSymbol = /[~`!@#$%^&*()\-+={}[\]|:;"'<>,.?/]/;
export const NEW_CHECKS = {
  oneUpperCase,
  oneLowerCase,
  oneDigit,
  oneSpecialSymbol,
  lengthCheck: /.{10,64}/, // Just in case, we already have `min` property set to 10
};
export const NEW_MIN = 10;
export const NEW_MIN_STRENGTH = 5;

const strengthColors = {
  0: colors.red.dark,
  1: colors.yellow.dark,
  2: colors.yellow.dark,
  3: colors.green.medium,
  4: colors.green.medium,
  5: colors.green.dark,
};

const PasswordInput = ({
  label,
  value,
  info,
  error,
  disabled,
  className,
  onChange,
  min,
  minStrength,
  checks,
  showStrength,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    strength: 0,
    strengthColor: colors.red.dark,
  });
  const [val, setVal] = useState(value);

  const checkPasswordStrength = (password) => {
    return Object.values(checks)
      .map((check) => (password.match(check) ? 1 : 0))
      .reduce((acc, check) => {
        return acc + check;
      }, 0);
  };

  const handleChange = (event) => {
    const strength =
      event.target.value.length >= min ? checkPasswordStrength(event.target.value) : 0;
    setVal(event.target.value);
    setPasswordStrength({
      strength,
      strengthColor: strengthColors[strength],
    });
    onChange({ val: event.target.value.replace(/\s/g, ''), strength });
  };

  let passwordCheck = null;
  if (showStrength) {
    passwordCheck = (
      <button type="button" className="password-strength">
        {passwordStrength.strength >= minStrength ? (
          <Icon size={24} icon={icons.ok} color={passwordStrength.strengthColor} />
        ) : null}
      </button>
    );
  }

  return (
    <div css={styles} className={className}>
      <span className="input-container">
        <Input
          label={label}
          info={info}
          error={error}
          value={val}
          {...props}
          type={showPassword ? 'text' : 'password'}
          disabled={disabled}
          onChange={(e) => handleChange(e, min)}
        />
        {passwordCheck}
        <button
          type="button"
          className="password-view"
          onClick={() => setShowPassword(!showPassword)}
        >
          <Icon
            size={24}
            icon={showPassword ? icons.visibilityOff : icons.visibility}
            color={colors.cyan.dark}
          />
        </button>
      </span>
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  min: PropTypes.number,
  minStrength: PropTypes.number,
  checks: PropTypes.object,
  showStrength: PropTypes.bool,
};

PasswordInput.defaultProps = {
  min: 10,
  minStrength: 5,
  showStrength: true,
  checks: NEW_CHECKS,
};

export default PasswordInput;
