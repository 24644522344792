import axios from 'axios';
import { domainLocation } from '../../shared/domainResolver';
import { Country } from '../../shared/country.enum';
import { InspectionStatus } from '../../shared/inspection.enum';
import * as actions from './action-types';

export const getProductsFromNed = () => {
  return axios.get(`users/products?country=${domainLocation}`);
};

export const getProductsFromPolicies = () => {
  return axios.get(`v2/users/products`);
};

export const getProducts =
  domainLocation === Country.BR ? getProductsFromPolicies : getProductsFromNed;

export const loadCars = () => async (dispatch) => {
  dispatch({
    type: actions.LOAD_CARS,
  });
  try {
    const res = await getProducts();
    dispatch({
      type: actions.LOAD_CARS_SUCCESS,
      payload: res.data.cars,
    });
  } catch (err) {
    dispatch({ type: actions.LOAD_CARS_FAIL });
    console.error('actions.LOAD_CARS_FAIL', err);
  }
};

/** @note br clients dont do inspections (at the moment) this is just a passthrough function */
export const loadInspectionsCarsForBr = () => (dispatch, getState) => {
  dispatch({
    type: actions.LOAD_INSPECTIONS_CARS_SUCCESS,
    payload: getState().cars.cars,
  });
};

/** @note this code is the same as in the 123mobile-app */
export const loadInspectionsCarsForNonBr = () => async (dispatch, getState) => {
  const { cars } = getState().cars;
  dispatch({
    type: actions.LOAD_INSPECTIONS_CARS,
  });
  try {
    const inspectionsByCarId = new Map();
    const inspectionCarsPromises = cars.map((car) => {
      return axios.get(`users/products/cars/${car.id}/inspection`, {
        params: {
          country: domainLocation,
        },
        headers: {
          Accept: 'application/json',
        },
        transformResponse: (responseJSON) => {
          const inspection = JSON.parse(responseJSON);
          if (car.inspection?.status === InspectionStatus.VALID) {
            // do nothing, just set the same car.inspection data
            inspectionsByCarId.set(car.id, car.inspection);
          } else if (inspection) {
            inspectionsByCarId.set(car.id, {
              ...inspection,
              // retain this value, because is not returned in 123inspection currently
              manual: car.inspection?.manual,
            });
          }
        },
      });
    });
    await Promise.all(inspectionCarsPromises);
    const augmentedCars = cars.map((car) => ({
      ...car,
      inspection: inspectionsByCarId.get(car.id),
    }));
    dispatch({
      type: actions.LOAD_INSPECTIONS_CARS_SUCCESS,
      payload: augmentedCars,
    });
  } catch (err) {
    dispatch({ type: actions.LOAD_INSPECTIONS_CARS_FAIL });
    console.error('actions.LOAD_INSPECTIONS_CARS_FAIL', err);
  }
};

export const loadInspectionsCars =
  domainLocation === Country.BR ? loadInspectionsCarsForBr : loadInspectionsCarsForNonBr;

/** @note this code is almost the same as in 123mobile-app */
export const checkExpiredInspectionsValid = () => (dispatch, getState) => {
  const { cars } = getState().cars;
  const now = Date.now();
  const ONE_MONTH_IN_MILLISECONDS_SLIGHTY = 2590000000;
  const approvedInspections = [];
  cars.forEach((car) => {
    if (car.inspection?.status === InspectionStatus.VALID) {
      const localStorageItemId = `car_${car.id}`;
      const savedTimestamp = localStorage.getItem(localStorageItemId);
      if (savedTimestamp) {
        const savedTimestampInt = parseInt(savedTimestamp);
        if (now - savedTimestampInt >= ONE_MONTH_IN_MILLISECONDS_SLIGHTY) {
          // valid inspection expirated, dont show badge message anymore
          // do nothing
        } else {
          // badge message has time left still to be displayed
          approvedInspections.push(car.id);
        }
      } else {
        // badge message should be start been displaying for now
        localStorage.setItem(`car_${car.id}`, now);
        approvedInspections.push(car.id);
      }
    }
  });
  dispatch({
    type: actions.APPROVED_INSPECTIONS_CARS,
    payload: approvedInspections,
  });
};
