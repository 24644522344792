module.exports = {
  applicationId: '5fad8ff0aa357e0faeba0144',
  attention: {
    customerSupport: 'clientes.argentina@123seguro.com',
    phone: '0800-3456-123',
    whatsapp: '+54 9 11 5902 2631',
  },
  services: {
    s3: 'https://dev-fileservice.123seguro.com',
  },
  termsLink: 'https://dev.123seguro.com.ar/terminos-y-condiciones',
  brandingS3URL: 'https://branding.s3.us-west-2.amazonaws.com',
  gtmIds: {
    ar: 'GTM-N8SRQZL',
    cl: 'GTM-N8SRQZL',
    co: 'GTM-N8SRQZL',
    br: 'GTM-N8SRQZL',
  },
  hotjar: null,
  baseUrl: 'http://dev.123seguro',
  basePath: '/autogestion',
};
