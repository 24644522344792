import React from 'react';
import PropTypes from 'prop-types';

function createScriptTag(src) {
  const script = document.createElement('script');
  if (src) {
    script.setAttribute('src', src);
  }
  script.setAttribute('type', 'text/javascript');
  return script;
}

function appendScriptTagToHead(scriptTag) {
  const head = document.querySelector('head');
  head.appendChild(scriptTag);
}

function addOneTrustSdkStubScript(dataDomainScript, onLoad) {
  const script = createScriptTag('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
  script.setAttribute('data-domain-script', dataDomainScript);
  script.onload = onLoad;
  appendScriptTagToHead(script);
}

function addOneTrustOptanonWrapperScript() {
  const script = createScriptTag();
  script.appendChild(document.createTextNode('function OptanonWrapper() {}'));
  appendScriptTagToHead(script);
}

const CookieConsenter = ({ dataDomainScript, onLoad }) => {
  const [didSdkStubLoad, setDidSdkStubLoad] = React.useState(false);

  React.useEffect(() => {
    addOneTrustSdkStubScript(dataDomainScript, () => {
      setDidSdkStubLoad(true);
    });
    addOneTrustOptanonWrapperScript();
  }, [dataDomainScript]);

  React.useEffect(() => {
    if (didSdkStubLoad) {
      onLoad();
    }
  }, [didSdkStubLoad, onLoad]);

  return null;
};

CookieConsenter.propTypes = {
  dataDomainScript: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default CookieConsenter;
